import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//import { registerStart } from "../../store/register/registerActions";
import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import LoadingWrapper from '../../../../components/LoadingCustom/LoadingWrapper';
import {
  addMemberStart,
  addMemberSuccess,
} from '../../../../store/user/addMember/addMemberActions';
import { getMember } from '../../../../store/user/member/memberActions';

const AddNewChildren = ({
  paramsQuery,
  setParamsQuery,
  setOpenAdd,
  setLoadingList,
  handleClose,
}) => {
  const dispatch = useDispatch();
  const roleID = 3;

  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({});

  const addMemberStatus = useSelector((state) => state.addMemberReducer.items);

  useEffect(() => {
    if (addMemberStatus.status === true) {
      setLoadingList(true);
      setLoading(false);
      setOpenAdd(false);

      const paramsTemp = { ...paramsQuery, page: 1 };

      dispatch(getMember(paramsTemp));
      setParamsQuery(paramsTemp);
      resetStatusAction();
    } else if (!addMemberStatus.status && addMemberStatus.isError) {
      setLoading(false);
      resetStatusAction();
    }
  }, [addMemberStatus]);

  const resetStatusAction = () => {
    return dispatch(addMemberSuccess({ isLoading: false, status: false, isError: false }));
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
      role: `${roleID}`,
    });
  };

  const handleChangeChecked = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.checked,
    });
  };

  const handleSubmit = () => {
    if (!values.name) {
      alert('Please enter field FullName');
      return;
    } else if (!values.password) {
      alert('Please enter field Password');
      return;
    } else if (!values.phone) {
      alert('Please enter field Phone');
      return;
    } else if (!values.email) {
      alert('Please enter field Email');
      return;
    } else if (!values.type) {
      alert('Please select field Type');
      return;
    }

    setLoading(true);

    // Handle submit success
    dispatch(
      addMemberStart({
        ...values,
        user_verified: values.user_verified ? '1' : '0',
      })
    );
  };

  return (
    <>
      <DialogTitle className="fw-bold">
        <span>Add New Children</span>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <LoadingWrapper loading={loading}>
          <div className="addNewChildren_main fs-15 pt-2 pb-2">
            <form className="" autoComplete="off" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-12 col-lg-6 mb-2">
                  <TextField
                    name="name"
                    fullWidth
                    required
                    id="outlined-fullname"
                    label="Fullname"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-12 col-lg-6 mb-2">
                  <TextField
                    name="password"
                    type={'password'}
                    fullWidth
                    required
                    id="outlined-password"
                    label="Password"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-12 col-lg-6 mb-2">
                  <TextField
                    name="phone"
                    fullWidth
                    required
                    id="phone"
                    label="Phone"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-12 col-lg-6 mb-2">
                  <TextField
                    name="email"
                    required
                    fullWidth
                    id="outlined-email"
                    label="Email"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-12 col-lg-6 mb-2">
                  <TextField
                    name="address"
                    fullWidth
                    id="outlined-address"
                    label="Address"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-12 col-lg-6 mb-2">
                  <FormControl fullWidth>
                    <InputLabel id="types" required>
                      Type
                    </InputLabel>
                    <Select
                      labelId="types"
                      id="outlined-types"
                      name="type"
                      required
                      label="Type"
                      onChange={handleChange}
                    >
                      <MenuItem value={1}>Children</MenuItem>
                      <MenuItem value={2}>People at risk of stroke</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="col-12 col-lg-6 mb-2">
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox onChange={handleChangeChecked} name="user_verified" />}
                      label="User Verified"
                    />
                  </FormGroup>
                </div>
              </div>
              <div className="mw-930 mx-auto text-end">
                <Button
                  color="primary"
                  className="fw-bold"
                  variant="contained"
                  size="large"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </LoadingWrapper>
      </DialogContent>
    </>
  );
};
export default AddNewChildren;
