import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DialogContent, DialogTitle, IconButton, TextField } from '@mui/material';
import { Button } from '@mui/material';
import { getMember } from '../../../../store/user/member/memberActions';
import { getItemEdit, updateItemEdit } from '../../../../store/user/editMember/editMemberActions';
import CloseIcon from '@mui/icons-material/Close';
import { message } from 'antd';
import LoadingWrapper from '../../../../components/LoadingCustom/LoadingWrapper';

const EditBodyguard = ({ selectedID, name, page, pageSize, setLoadingList, handleClose, setOpenEdit }) => {
  const dispatch = useDispatch();

  const roleID = 5;

  const [memberDataDetail, setMemberDataDetail] = useState([]);
  const [values, setValues] = useState([]);
  const [emailDtl, setEmailDtl] = useState('');
  const [phoneDtl, setPhoneDtl] = useState('');
  const [loading, setLoading] = useState(true);

  const memDetailData = useSelector((state) => state.memberDetailReducer.items.data);
  const editStatus = useSelector((state) => state.editMemberReducer.items);

  useEffect(() => {
    if (memDetailData.error_code === 0) {
      setMemberDataDetail(memDetailData.data);
      const infoData = memDetailData.data;
      setEmailDtl(infoData.email);
      setPhoneDtl(infoData.phone);

      setLoading(false);
    } else if (memDetailData.error_code === 1) {
      setLoading(false);
      handleClose();
    }
  }, [memDetailData]);

  // set Value State
  useEffect(() => {
    if (memberDataDetail) {
      setValues({
        name: memberDataDetail.name,
        address: memberDataDetail.address,
      });
    }
  }, [memberDataDetail]);

  useEffect(() => {
    if (editStatus.status === true) {
      setLoadingList(true);
      setLoading(false);
      setOpenEdit(false);

      dispatch(getMember({ roleID: roleID, page: page, per_page: pageSize }));
      dispatch(updateItemEdit({ isLoading: false, status: false, isError: false }));

      message.success('Updated user success!');
    } else if (!editStatus.status && editStatus.isError) {
      setLoading(false);
      message.error('Update user failed!');
    }
  }, [editStatus]);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = () => {

    if (!values.name) {
      alert('Please enter field FullName');
      return;
    }

    if (!values.password) {
      alert('Please enter field Password');
      return;
    }

    if (!values.phone) {
      alert('Please enter field Phone');
      return;
    }

    if (!values.email) {
      alert('Please enter field Email');
      return;
    }

    setLoading(true);

    // Handle submit success
    dispatch(
      getItemEdit({
        selectedID: selectedID[0],
        values: values,
      })
    );
  };

  return (
    <>
      <DialogTitle className="fw-bold">
        <span>Edit Bodyguard {name}</span>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <LoadingWrapper loading={loading}>
          <div className="addNewBodyGuard_main fs-15 pt-2 pb-2">
            <form className="" autoComplete="off" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-12 col-lg-6 mb-2">
                  <TextField
                    name="name"
                    value={values.name}
                    fullWidth
                    required
                    id="outlined-fullname"
                    label="Fullname"
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className="col-12 col-lg-6 mb-2">
                  <TextField
                    name="password"
                    type={'password'}
                    fullWidth
                    required
                    id="outlined-password"
                    label="Password"
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className="col-12 col-lg-6 mb-2">
                  <TextField
                    name="email"
                    value={emailDtl}
                    fullWidth
                    required
                    id="outlined-email"
                    label="Email"
                    disabled
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className="col-12 col-lg-6 mb-2">
                  <TextField
                    name="phone"
                    value={phoneDtl}
                    fullWidth
                    required
                    id="outlined-phone"
                    label="Phone"
                    disabled
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className="col-12 col-lg-6 mb-2">
                  <TextField
                    name="address"
                    value={values.address}
                    fullWidth
                    id="outlined-address"
                    label="Address"
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
              </div>
              <div className="mw-930 mx-auto text-end">
                <Button
                  color="primary"
                  className="fw-bold"
                  variant="contained"
                  size="large"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </LoadingWrapper>
      </DialogContent>
    </>
  );
};
export default EditBodyguard;
