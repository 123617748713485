import { ArrowBack } from '@mui/icons-material';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import MuiTable from '../../../../components/MuiTable';
import {
  getExaminationHistories,
  getExaminationHistoryDetail,
} from '../../../../store/examinationHistory/examinationHistoryActions';
import { formatDate } from '../../../../utils/supportFunction';
import UpdateExaminationHistory from './UpdateExaminationHistory';
import LoadingWrapper from '../../../../components/LoadingCustom/LoadingWrapper';

const ExaminationHistory = () => {
  const dispatch = useDispatch();
  const paramsUrl = useParams();
  const patientId = paramsUrl.id;

  const [rawData, setRawData] = useState([]);
  const [selectedID, setSelectedID] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(15);
  const [totalItem, setTotalItem] = useState(0);
  const [showModalUpdate, setShowModalUpdate] = useState(false);
  const [nameSelected, setNameSelected] = useState(false);
  const [paramsFilter, setParamsFilter] = useState({
    page: 1,
    per_page: pageSize,
    'filters[children_id]': patientId,
  });

  const examinationHistories = useSelector(
    (state) => state.examinationHistoryReducer.examinationHistories
  );

  useEffect(() => {
    if (patientId) {
      dispatch(getExaminationHistories(paramsFilter));
    }
  }, [patientId]);

  useEffect(() => {
    if (examinationHistories.data?.data?.length > 0) {
      const paginationTemp = examinationHistories.data.meta.pagination;

      setRawData(examinationHistories.data.data);
      setPage(paginationTemp.current_page - 1);
      setTotalItem(paginationTemp.total);
    } else {
      setRawData([]);
      setPage(0);
      setTotalItem(0);
    }
  }, [examinationHistories]);

  const TableGridStyle = useMemo(
    () => ({ width: '100%', height: 'calc(100vh - 250px)', minHeight: 500 }),
    []
  );

  const goBack = () => {
    window.history.back();
  };

  //Edit Children
  const handleEditRow = () => {
    setShowModalUpdate(true);
    setNameSelected(selectedRows[0].name);
    dispatch(getExaminationHistoryDetail(selectedID));
  };

  //handle page change
  const handlePageChange = (newPage) => {
    setPage(newPage);
    setParamsFilter((prev) => ({ ...prev, page: newPage + 1 }));

    dispatch(getExaminationHistories({ ...paramsFilter, page: newPage + 1 }));
  };

  //handle page size change
  const handlePageSizeChange = (newPageSize) => {
    const paramsFilterTemp = { ...paramsFilter, page: 1, per_page: newPageSize };

    setPage(0);
    setPageSize(newPageSize);
    setParamsFilter(paramsFilterTemp);

    dispatch(getExaminationHistories(paramsFilterTemp));
  };

  const columns = [
    {
      headerName: 'ID',
      field: 'id',
      width: 60,
    },
    {
      headerName: 'Name',
      field: 'children_info',
      width: 250,
      valueFormatter: (params) => params.value?.name,
    },
    {
      headerName: 'Diagnosis',
      field: 'diagnosis',
      width: 250,
    },
    {
      headerName: 'Treatment',
      field: 'treatment',
      width: 250,
    },
    {
      headerName: 'Doctor',
      field: 'doctor_info',
      width: 250,
      valueFormatter: (params) => params.value?.name,
    },
    {
      headerName: 'Update Time',
      field: 'updated_at',
      width: 150,
      valueFormatter: (params) => formatDate(params.value),
    },
  ];

  return (
    <div>
      <p className="m-0 back-icon d-inline-block">
        <span className="d-flex align-items-center fw-bold cursor-pointer" onClick={() => goBack()}>
          <ArrowBack className="me-1" /> Back
        </span>
      </p>

      <div className="pt-3">
        <LoadingWrapper loading={examinationHistories.isLoading}>
          <MuiTable
            setSelectedID={setSelectedID}
            setSelectedRows={setSelectedRows}
            columns={columns}
            rows={rawData}
            gridStyle={TableGridStyle}
            checkboxSelection
            singleSelected
            rowID="id"
            showEditButton
            handleEditRow={handleEditRow}
            rowsPerPageOptions={[5, 10, 15, 20, 50, 100]}
            pagination
            page={page}
            pageSize={pageSize}
            rowCount={totalItem}
            loading={examinationHistories.isLoading}
            onPageSizeChange={handlePageSizeChange}
            onPageChange={handlePageChange}
            labelRowsPerPage={'Examination'}
          />
        </LoadingWrapper>

        {/* Modal Create */}
        {/* <CreateExaminationHistory showModal={showModalCreate} setShowModal={setShowModalCreate} /> */}

        {/* Modal Update */}
        <UpdateExaminationHistory
          selectedID={selectedID}
          nameSelected={nameSelected}
          paramsFilter={paramsFilter}
          showModal={showModalUpdate}
          setShowModal={setShowModalUpdate}
        />
      </div>
    </div>
  );
};

export default ExaminationHistory;
