import React from "react";
import LoginArea from "./LoginArea";
import "./login.scss";

const SignIn = () => {
  return (
    <>
      <div className="d-flex align-items-center w-100 h-100vh justify-content-center">
        <div className="login-form border border-secondary shadow bg-white">
          <LoginArea />
        </div>
      </div>
    </>
  );
};
export default SignIn;
