import React from "react";
import "../dashboard.scss";

const HealthRecords = () => {
  return (
    <>
      <div>Health Records</div>
    </>
  );
};
export default HealthRecords;
