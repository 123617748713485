const API_CONFIGS = {
  API_BASE_URL: process.env.REACT_APP_API_BE_URI,
  API_BASE_VER: '/api/admin',
  API_VER_2: '/api/v2/admin',
};

const AUTHORIZATION_KEY = {
  TOKEN_TYPE: 'token_type',
  ACCESS_TOKEN: 'access_token',
  AUTHORIZED_TOKEN_HEADER: 'authorized_token_header',
  TOKEN_USER: 'token_user',
  TOKEN_USER_EXPIRE: 'token_user_expire',
};

export { API_CONFIGS, AUTHORIZATION_KEY };
