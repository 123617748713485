import { all, call, put, takeLatest } from 'redux-saga/effects';
import apiInstance from '../../../../gateway/Instance';
import { message } from 'antd';

import { updateHospitalEdit } from '../editHospitalActions';

import types from '../editHospitalActionTypes';

const getItemEditAPI = async ({ selectedID, values }) => {
  const response = await apiInstance
    .put(`/hospital/${selectedID}`, values)
    .then((response) => {
      if (response.status === 200) {
        message.success('Updated hospital success');
      }
    })
    .catch((error) => console.log(error));
  return response;
};

export function* getEditHospitalCredentials({ payload: { selectedID, values } }) {
  try {
    yield put(updateHospitalEdit({ isLoading: true, status: false, isError: false }));
    yield getItemEditAPI({ selectedID, values });
    yield put(updateHospitalEdit({ isLoading: false, status: true, isError: false }));
  } catch (error) {
    yield put(updateHospitalEdit({ isLoading: false, status: false, isError: true }));
  }
}

export function* onEditHospitalStart() {
  yield takeLatest(types.GET_HOSPITAL_EDIT, getEditHospitalCredentials);
}

export function* editHospitalSagas() {
  yield all([call(onEditHospitalStart)]);
}
