import React from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";

const ActiveLink = ({ to, name, icon, maintitle, onClose }) => {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <>
      <Link
        className={`${match && "active"} d-flex w-100 align-items-center`}
        to={{
          pathname: to,
          state: { maintitle },
        }}
        state={maintitle}
        onClick={onClose}
      >
        {icon}
        <span className="ps-1">{name}</span>
      </Link>
    </>
  );
};

export default ActiveLink;
