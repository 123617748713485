import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import MuiTable from '../../../components/MuiTable';
import { getMember } from '../../../store/user/member/memberActions';
import { formatDate } from '../../../utils/supportFunction';
import LoadingWrapper from '../../../components/LoadingCustom/LoadingWrapper';

const PatientsExaminationHistory = () => {
  const dispatch = useDispatch();

  const roleID = 3;
  const childrenType = 2;
  let accessToken = localStorage.getItem('access_token');

  const [rowsData, setRowsData] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(15);
  const [totalItem, setTotalItem] = useState(0);
  const [loading, setLoading] = useState(true);

  const memData = useSelector((state) => state.memberReducer.items.data);

  useEffect(() => {
    if (accessToken) {
      dispatch(getMember({ roleID: roleID, page: 1, per_page: pageSize, childrenType }));
    }
  }, [accessToken]);

  useEffect(() => {
    if (memData.error_code === 0 && memData.results.filter_by_role === roleID) {
      const paginationTemp = memData.results.meta.pagination;

      setRowsData(memData.results.data);
      setPage(paginationTemp.current_page - 1);
      setTotalItem(paginationTemp.total);
      setLoading(false);
    } else {
      setRowsData([]);
      setPage(0);
      setTotalItem(0);
    }
  }, [memData]);

  const TableGridStyle = useMemo(
    () => ({ width: '100%', height: 'calc(100vh - 250px)', minHeight: 500 }),
    []
  );

  //handle page change
  const handlePageChange = (newPage) => {
    setLoading(true);
    setPage(newPage);
    dispatch(
      getMember({
        roleID: roleID,
        page: newPage + 1,
        per_page: pageSize,
        childrenType,
      })
    );
  };

  //handle page size change
  const handlePageSizeChange = (newPageSize) => {
    setPage(0);
    setLoading(true);
    setPageSize(newPageSize);
    dispatch(
      getMember({
        roleID: roleID,
        per_page: newPageSize,
        page: 1,
        childrenType,
      })
    );
  };

  const renderLinkExamination = (p) => {
    return (
      <Link
        to={`/examination-history/${encodeURIComponent(p.row?.id)}`}
        state={p.value}
        className="cursor-pointer text-decoration-underline"
      >
        {p.value}
      </Link>
    );
  };

  const columns = [
    {
      headerName: 'ID',
      field: 'id',
      width: 60,
    },
    {
      headerName: 'Name',
      field: 'name',
      width: 250,
      renderCell: renderLinkExamination,
    },
    {
      headerName: 'Phone',
      field: 'phone',
      width: 150,
    },
    {
      headerName: 'Email',
      field: 'email',
      width: 250,
    },
    {
      headerName: 'Address',
      field: 'address',
      width: 350,
    },
    {
      headerName: 'Create Time',
      field: 'created_at',
      width: 150,
      valueFormatter: (params) => formatDate(params.value),
    },
  ];

  return (
    <div className="pt-3">
      <LoadingWrapper loading={loading}>
        <MuiTable
          columns={columns}
          rows={rowsData}
          gridStyle={TableGridStyle}
          rowID="id"
          rowsPerPageOptions={[5, 10, 15, 20, 50, 100]}
          pagination
          page={page}
          pageSize={pageSize}
          rowCount={totalItem}
          loading={loading}
          onPageSizeChange={handlePageSizeChange}
          onPageChange={handlePageChange}
          labelRowsPerPage={'Patient'}
        />
      </LoadingWrapper>
    </div>
  );
};

export default PatientsExaminationHistory;
