import { all, call, put, takeEvery } from 'redux-saga/effects';
import apiInstance from '../../../gateway/Instance';

import { showProfile } from '../profileActions';

import types from '../profileActionTypes';

const getProfileAPI = async () => {
  const response = apiInstance.get(`/profile`);
  return response;
};

export function* getProfileCredentials({ payload }) {
  try {
    yield put(showProfile({ isLoading: true, data: [], isError: false }));
    const result = yield call(getProfileAPI, payload);
    yield put(showProfile({ isLoading: false, data: result, isError: false }));
  } catch (error) {
    yield put(showProfile({ isLoading: false, data: [], isError: true }));
  }
}

export function* onProfileStart() {
  yield takeEvery(types.GET_PROFILE, getProfileCredentials);
}

export function* profileSagas() {
  yield all([call(onProfileStart)]);
}
