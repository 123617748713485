import types from './addressActionTypes';

const initialState = {
  provinces: { isLoading: true, data: [], isError: false },
  districtsProvince: { isLoading: false, data: [], isError: false },
  wards: { isLoading: false, data: [], isError: false },
};

const addressReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_PROVINCES:
      return { ...state, provinces: action.payload };
    case types.SHOW_DISTRICTS_BY_PROVINCE:
      return { ...state, districtsProvince: action.payload };
    case types.SHOW_WARDS:
      return { ...state, wards: action.payload };
    default:
      return state;
  }
};

export default addressReducer;
