import { all, call } from 'redux-saga/effects';

import { authSagas } from './auth/authSagas';
import { memberSagas } from './user/member/memberSagas';
import { deleteMemberSagas } from './user/deleteMember/deleteMemberSagas';
import { addMemberSagas } from './user/addMember/addMemberSagas';
import { editMemberSagas } from './user/editMember/editMemberSagas';
import { memberDetailSagas } from './user/memberDetail/memberDetailSagas';
import { profileSagas } from './profile/profileSagas';
import { hospitalSagas } from './hospital/hospitalList/hospitalSagas';
import { hospitalDetailSagas } from './hospital/hospitalDetail/hospitalDetailSagas';
import { editHospitalSagas } from './hospital/editHospital/editHospitalSagas';
import { deleteHospitalSagas } from './hospital/deleteHospital/deleteHospitalSagas';
import { addHospitalSagas } from './hospital/addHospital/addHospitalSagas';
import { changePasswordSagas } from './user/changePassword/changePasswordSagas';
import { addressSagas } from './address/addressSagas';
import { orderSagas } from './order/orderSagas';
import { searchUsersSagas } from './user/searchUsers/searchUsersSagas';
import { examinationHistorySagas } from './examinationHistory/examinationHistorySagas';
import { packageSagas } from './package/packageSagas';

export default function* rootSaga() {
  yield all([
    call(authSagas),
    call(memberSagas),
    call(deleteMemberSagas),
    call(addMemberSagas),
    call(editMemberSagas),
    call(memberDetailSagas),
    call(profileSagas),
    call(hospitalSagas),
    call(hospitalDetailSagas),
    call(editHospitalSagas),
    call(deleteHospitalSagas),
    call(addHospitalSagas),
    call(changePasswordSagas),
    call(addressSagas),
    call(orderSagas),
    call(searchUsersSagas),
    call(examinationHistorySagas),
    call(packageSagas),
  ]);
}
