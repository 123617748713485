import { all, call, put, takeLatest } from 'redux-saga/effects';
import apiInstance from '../../../../gateway/Instance';
import { message } from 'antd';

import { updateItemDelete } from '../deleteMemberActions';

import types from '../deleteMemberActionTypes';

const getItemDeleteAPI = async (deleteID) => {
  const response = await apiInstance
    .delete(`/user/${deleteID}`)
    .then((response) => {
      if (response.status === 200) {
        message.success('Deleted user success');
        return response;
      }

      throw response;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
  return response;
};

const deleteUsersAPI = async (ids) => {
  const response = await apiInstance
    .delete(`/user`, { data: { ids } })
    .then((response) => {
      if (response.error_code) {
        throw response;
      }

      return response;
    })
    .catch((error) => {
      console.log('deleteUsersAPI', error);
      message.error('System error!');
      throw error;
    });
  return response;
};

export function* getDeleteMemberCredentials({ payload: deleteID }) {
  try {
    yield getItemDeleteAPI(deleteID);
    yield put(updateItemDelete({ isLoading: false, status: true, isError: false }));
  } catch (error) {
    yield put(updateItemDelete({ isLoading: false, status: false, isError: true }));
  }
}

export function* deleteUsersCredentials({ payload: deleteID }) {
  try {
    yield put(updateItemDelete({ isLoading: true, status: false, isError: false }));
    yield deleteUsersAPI(deleteID);
    yield put(updateItemDelete({ isLoading: false, status: true, isError: false }));
  } catch (error) {
    yield put(updateItemDelete({ isLoading: false, status: false, isError: true }));
  }
}

export function* onDeleteMemberStart() {
  yield takeLatest(types.GET_ITEM_DELETE, getDeleteMemberCredentials);
  yield takeLatest(types.DELETE_USERS, deleteUsersCredentials);
}

export function* deleteMemberSagas() {
  yield all([call(onDeleteMemberStart)]);
}
