import React from "react";
import "../dashboard.scss";

const PackageSetting = () => {
  return (
    <>
      <div>Package Setting</div>
    </>
  );
};
export default PackageSetting;
