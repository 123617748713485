import types from './searchUsersActionTypes';

const initialState = {
  items: { isLoading: false, data: [], isError: false, isClearSearch: false },
  selectedUser: { status: false, role: null, userId: null },
  childrenType: 0,
};

const searchUsersReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_SEARCH_USERS:
      return { ...state, items: action.payload };
    case types.SET_SELECTED_USER:
      return { ...state, selectedUser: action.payload };
    case types.SET_CHILDREN_TYPE:
      return { ...state, childrenType: action.payload };
    default:
      return state;
  }
};

export default searchUsersReducer;
