/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//import { registerStart } from "../../store/register/registerActions";
import CloseIcon from '@mui/icons-material/Close';
import { DialogContent, DialogTitle, IconButton, TextField } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { Button, Form, Select, notification } from 'antd';
import LoadingWrapper from '../../../../components/LoadingCustom/LoadingWrapper';
import {
  getDistrictsByProvince,
  getProvinces,
  getWards,
  showDistrictsByProvince,
  showWards,
} from '../../../../store/address/addressActions';
import {
  addMemberStart,
  addMemberSuccess,
} from '../../../../store/user/addMember/addMemberActions';
import { getMember } from '../../../../store/user/member/memberActions';

const AddNewSupervisor = ({ pageSize, setOpenAdd, setLoadingList, handleClose }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const roleID = 4;

  const [loading, setLoading] = useState(false);
  const [checkedVal, setCheckedVal] = useState(false);
  const [optionsProvinces, setOptionsProvinces] = useState([]);
  const [optionsDistricts, setOptionsDistricts] = useState([]);
  const [optionsWards, setOptionsWards] = useState([]);

  const addMemberStatus = useSelector((state) => state.addMemberReducer.items);
  const provinces = useSelector((state) => state.addressReducer.provinces);
  const districts = useSelector((state) => state.addressReducer.districtsProvince);
  const wards = useSelector((state) => state.addressReducer.wards);

  useEffect(() => {
    dispatch(getProvinces());
  }, []);

  useEffect(() => {
    if (!provinces.isLoading && provinces.data?.length > 0) {
      const optionsProvincesTemp = provinces.data.map((item) => ({
        label: item.name,
        value: item.id,
      }));

      setOptionsProvinces(optionsProvincesTemp);
    } else {
      setOptionsProvinces([]);
    }
  }, [provinces]);

  useEffect(() => {
    if (!districts.isLoading && districts.data?.length > 0) {
      const optionsDistrictsTemp = districts.data.map((item) => ({
        label: item.name,
        value: item.id,
      }));

      setOptionsDistricts(optionsDistrictsTemp);
    } else {
      setOptionsDistricts([]);
    }
  }, [districts]);

  useEffect(() => {
    if (!wards.isLoading && wards.data?.length > 0) {
      const optionsWardsTemp = wards.data.map((item) => ({
        label: item.name,
        value: item.id,
      }));

      setOptionsWards(optionsWardsTemp);
    } else {
      setOptionsWards([]);
    }
  }, [wards]);

  useEffect(() => {
    if (addMemberStatus.status === true) {
      setLoadingList(true);
      setLoading(false);
      setOpenAdd(false);

      dispatch(getMember({ roleID: roleID, page: 1, per_page: pageSize }));
      resetStatusAction();
    } else if (!addMemberStatus.status && addMemberStatus.isError) {
      setLoading(false);
      resetStatusAction();
    }
  }, [addMemberStatus]);

  const resetStatusAction = () => {
    return dispatch(addMemberSuccess({ isLoading: false, status: false, isError: false }));
  };

  const handleChangeProvince = (value) => {
    setOptionsWards([]);
    form.resetFields(['district_id', 'ward_id']);

    if (!value) {
      dispatch(showDistrictsByProvince({ isLoading: false, data: [], isError: false }));
      return;
    }

    dispatch(getDistrictsByProvince({ province_ids: [value] }));
  };

  const handleChangeDistrict = (value) => {
    form.resetFields(['ward_id']);

    if (!value) {
      dispatch(showWards({ isLoading: false, data: [], isError: false }));
      return;
    }

    dispatch(getWards({ district_id: value }));
  };

  const handleChangeChecked = (event) => {
    setCheckedVal(event.target.checked);
  };

  const handleSubmit = (values) => {
    if (!values.name) {
      alert('Please enter field FullName');
      return;
    } else if (!values.password) {
      alert('Please enter field Password');
      return;
    } else if (values?.password?.length < 6) {
      alert('The password must be at least 6 characters.');
      return;
    } else if (!values.phone) {
      alert('Please enter field Phone');
      return;
    } else if (!values.email) {
      alert('Please enter field Email');
      return;
    } else if (!values.province_id) {
      alert('Please enter field Province');
      return;
    } else if (!values.district_id) {
      alert('Please enter field District');
      return;
    } else if (!values.ward_id) {
      alert('Please enter field Ward');
      return;
    }

    setLoading(true);

    const dataCreate = {
      ...values,
      role: roleID,
      user_verified: values.user_verified ? 1 : 0,
    };

    // Handle submit success
    dispatch(addMemberStart(dataCreate));
  };

  const onFinishFailed = (errorInfo) => {
    notification.error({
      message: 'Error',
      description: 'Add new supervisor failed!',
    });
  };

  return (
    <>
      <DialogTitle className="fw-bold">
        <span>Add New Supervisor</span>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <LoadingWrapper loading={loading}>
          <div className="addNewSupervisor_main fs-15 pt-2 pb-2">
            <Form
              form={form}
              name="createSupervisor"
              onFinish={handleSubmit}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <div className="row">
                <div className="col-12 col-lg-6 mb-2">
                  <Form.Item name="name">
                    <TextField
                      name="name"
                      fullWidth
                      required
                      id="outlined-fullname"
                      label="Fullname"
                    />
                  </Form.Item>
                </div>
                <div className="col-12 col-lg-6 mb-2">
                  <Form.Item name="password">
                    <TextField
                      name="password"
                      type={'password'}
                      autoComplete="off"
                      fullWidth
                      id="outlined-password"
                      label="Password"
                    />
                  </Form.Item>
                </div>
                <div className="col-12 col-lg-6 mb-2">
                  <Form.Item name="email">
                    <TextField
                      name="email"
                      autoComplete="off"
                      fullWidth
                      required
                      id="outlined-email"
                      label="Email"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Form.Item>
                </div>
                <div className="col-12 col-lg-6 mb-2">
                  <Form.Item name="phone">
                    <TextField
                      name="phone"
                      autoComplete="off"
                      fullWidth
                      required
                      id="outlined-phone"
                      label="Phone"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Form.Item>
                </div>
                <div className="col-12 col-lg-6">
                  <Form.Item name="address">
                    <TextField
                      name="address"
                      autoComplete="off"
                      fullWidth
                      id="outlined-address"
                      label="Address"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Form.Item>
                </div>
                <div className="col-12 col-lg-6">
                  <Form.Item name="user_verified">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={!!checkedVal}
                            onChange={handleChangeChecked}
                            name="user_verified"
                          />
                        }
                        label="User Verified"
                      />
                    </FormGroup>
                  </Form.Item>
                </div>

                <div className="col-12 col-md-6">
                  <label className="mb-sm">
                    Province<span className="text-red"> *</span>
                  </label>
                  <Form.Item
                    name="province_id"
                    rules={[{ required: true, message: 'Missing province' }]}
                  >
                    <Select
                      showSearch
                      placeholder="Select a province"
                      options={optionsProvinces}
                      onChange={handleChangeProvince}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      className="ant-select-custom"
                    />
                  </Form.Item>
                </div>
                <div className="col-12 col-lg-6">
                  <label className="mb-sm">
                    District<span className="text-red"> *</span>
                  </label>
                  <Form.Item
                    name="district_id"
                    rules={[{ required: true, message: 'Missing district' }]}
                  >
                    <Select
                      showSearch
                      allowClear
                      placeholder="Select district"
                      options={optionsDistricts}
                      onChange={handleChangeDistrict}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      className="ant-select-custom"
                    />
                  </Form.Item>
                </div>
                <div className="col-12 col-lg-6">
                  <label className="mb-sm">
                    Ward<span className="text-red"> *</span>
                  </label>
                  <Form.Item name="ward_id" rules={[{ required: true, message: 'Missing ward' }]}>
                    <Select
                      showSearch
                      allowClear
                      placeholder="Select ward"
                      options={optionsWards}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      className="ant-select-custom"
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="mw-930 mx-auto text-end">
                <Button type="primary" className="fw-bold rounded" size="large" htmlType="submit">
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        </LoadingWrapper>
      </DialogContent>
    </>
  );
};
export default AddNewSupervisor;
