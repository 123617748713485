import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MuiTable from '../../../components/MuiTable';
import {
  getPackageDetail,
  getPackageTypes,
  getPackages,
} from '../../../store/package/packageActions';
import { formatMoney, objectIsEmpty } from '../../../utils/supportFunction';
import CreatePackage from './CreatePackage';
import UpdatePackage from './UpdatePackage';
import LoadingWrapper from '../../../components/LoadingCustom/LoadingWrapper';
import { deletePackageAPI } from '../../../store/package/packageSagas';
import { notification } from 'antd';

const Packages = () => {
  const dispatch = useDispatch();

  const [rawData, setRawData] = useState([]);
  const [packageTypes, setPackageTypes] = useState([]);
  const [selectedID, setSelectedID] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(15);
  const [totalItem, setTotalItem] = useState(0);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showModalCreate, setShowModalCreate] = useState(false);
  const [showModalUpdate, setShowModalUpdate] = useState(false);
  const [nameSelected, setNameSelected] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);

  const packages = useSelector((state) => state.packageReducer.packages);
  const packageTypesReducer = useSelector((state) => state.packageReducer.packageTypes);

  useEffect(() => {
    dispatch(getPackageTypes());
    dispatch(getPackages({ page: 1, per_page: pageSize }));
  }, []);

  useEffect(() => {
    if (!objectIsEmpty(packages.data) && packages.data?.data?.length > 0) {
      const dataTemp = packages.data.data;
      const metaTemp = packages.data.meta;

      setRawData(dataTemp);
      setPage(metaTemp.pagination.current_page - 1);
      setPageSize(metaTemp.pagination.per_page);
      setTotalItem(metaTemp.pagination.total);
    } else {
      setRawData([]);
      setPage(0);
      setTotalItem(0);
    }
  }, [packages]);

  useEffect(() => {
    if (packageTypesReducer.data?.length > 0) {
      setPackageTypes(packageTypesReducer.data);
    } else {
      setPackageTypes([]);
    }
  }, [packageTypesReducer]);

  const TableGridStyle = useMemo(
    () => ({ width: '100%', height: 'calc(100vh - 250px)', minHeight: 500 }),
    []
  );

  const handleDeleteRow = () => {
    setShowConfirmDelete(true);
    setNameSelected(selectedRows[0].name);
  };

  const handleCloseDelete = () => {
    setShowConfirmDelete(false);
  };

  const handleConfirmDelete = () => {
    setShowConfirmDelete(false);
    setLoadingPage(true);

    deletePackageAPI(selectedID)
      .then((res) => {
        setLoadingPage(false);

        if (res.error_code === 0) {
          dispatch(getPackages());
          notification.success({
            message: 'Success',
            description: 'Deleted package successfully!',
          });
        } else {
          notification.error({ message: 'Error', description: res.error_description });
        }

        return res;
      })
      .catch((error) => {
        console.log('🚀 ~ file: index.js:88 ~ deletePackageAPI ~ error:', error);
        notification.error({ message: 'Error', description: error.error_description });
        return error;
      });
  };

  //add new
  const handleAddNewRow = () => {
    setShowModalCreate(true);
  };

  //Edit Children
  const handleEditRow = () => {
    setShowModalUpdate(true);
    setNameSelected(selectedRows[0].name);
    dispatch(getPackageDetail(selectedID));
  };

  const handleCloseUpdate = () => {
    setShowModalUpdate(false);
  };

  //handle page change
  const handlePageChange = (newPage) => {
    setPage(newPage);
    dispatch(
      getPackages({
        page: newPage + 1,
        per_page: pageSize,
      })
    );
  };

  //handle page size change
  const handlePageSizeChange = (newPageSize) => {
    setPage(0);
    setPageSize(newPageSize);
    dispatch(
      getPackages({
        page: 1,
        per_page: newPageSize,
      })
    );
  };

  const formatPackageType = (params) => {
    if (!params.value || !packageTypes.length) {
      return '';
    }

    const result = packageTypes.find((item) => item.value === params.value);
    return result ? result.name : params.value;
  };

  const columns = [
    {
      headerName: 'ID',
      field: 'id',
      width: 60,
    },
    {
      headerName: 'Name',
      field: 'name',
      width: 250,
    },
    {
      headerName: 'Price',
      field: 'price',
      width: 150,
      valueFormatter: (params) => formatMoney(params.value),
    },
    {
      headerName: 'Type',
      field: 'product_type',
      width: 250,
      valueFormatter: formatPackageType,
    },
    {
      headerName: 'Value',
      field: 'value',
      width: 350,
    },
  ];

  return (
    <div>
      <LoadingWrapper loading={packages.isLoading || loadingPage}>
        <div className="pt-3">
          <MuiTable
            setSelectedID={setSelectedID}
            setSelectedRows={setSelectedRows}
            columns={columns}
            rows={rawData}
            gridStyle={TableGridStyle}
            checkboxSelection
            singleSelected
            rowID="id"
            showAddButton
            showEditButton
            showDeleteButton
            handleDeleteRow={handleDeleteRow}
            handleAddNewRow={handleAddNewRow}
            handleEditRow={handleEditRow}
            rowsPerPageOptions={[5, 10, 15, 20, 50, 100]}
            pagination
            page={page}
            pageSize={pageSize}
            rowCount={totalItem}
            loading={packages.isLoading}
            onPageSizeChange={handlePageSizeChange}
            onPageChange={handlePageChange}
            labelRowsPerPage={'Examination'}
          />

          {/* Modal Confirm Delete */}
          <Dialog open={showConfirmDelete} onClose={handleCloseDelete}>
            <DialogTitle id="alert-dialog-title">Are you sure delete "{nameSelected}"?</DialogTitle>
            <DialogActions>
              <Button onClick={handleCloseDelete}>No</Button>
              <Button onClick={handleConfirmDelete} autoFocus>
                Yes
              </Button>
            </DialogActions>
          </Dialog>

          {/* Modal Create */}
          <CreatePackage showModal={showModalCreate} setShowModal={setShowModalCreate} />

          {/* Modal Update */}
          <UpdatePackage
            selectedID={selectedID}
            nameSelected={nameSelected}
            showModal={showModalUpdate}
            setShowModal={setShowModalUpdate}
          />
        </div>
      </LoadingWrapper>
    </div>
  );
};

export default Packages;
