const packageActionTypes = {
  GET_PACKAGES: 'GET_PACKAGES',
  SET_PACKAGES: 'SET_PACKAGES',
  GET_PACKAGE_DETAIL: 'GET_PACKAGE_DETAIL',
  SET_PACKAGE_DETAIL: 'SET_PACKAGE_DETAIL',
  GET_PACKAGE_TYPES: 'GET_PACKAGE_TYPES',
  SET_PACKAGE_TYPES: 'SET_PACKAGE_TYPES',
};

export default packageActionTypes;
