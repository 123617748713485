import { INITIAL_STATE_REDUCER } from '../../constants/Constants';
import types from './examinationHistoryActionTypes';

const initialState = {
  examinationHistories: INITIAL_STATE_REDUCER.OBJECT_LOADING,
  examinationHistoryDetail: INITIAL_STATE_REDUCER.OBJECT,
  actions: INITIAL_STATE_REDUCER.ACTIONS,
};

const examinationHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_EXAMINATION_HISTORIES:
      return { ...state, examinationHistories: action.values };
    case types.SET_EXAMINATION_HISTORY_DETAIL:
      return { ...state, examinationHistoryDetail: action.values };
    case types.SET_ACTIONS_EXAMINATION_HISTORY:
      return { ...state, actions: action.values };
    default:
      return state;
  }
};

export default examinationHistoryReducer;
