import { all, call, put, takeLatest } from 'redux-saga/effects';
import apiInstance from '../../../../gateway/Instance';
import apiInstanceV2 from '../../../../gateway/InstanceV2';
import { message } from 'antd';

import {
  setEditChildrenServiceExpire,
  setEditServiceExpire,
  updateItemEdit,
} from '../editMemberActions';

import types from '../editMemberActionTypes';

const getItemEditAPI = async ({ selectedID, values }) => {
  const response = await apiInstance
    .put(`/user/${selectedID}`, values)
    .then((response) => {
      if (response.error_code) {
        message.error(response.error_description);
        throw response;
      }

      return response;
    })
    .catch((error) => {
      console.log(error);
      message.error(response.error_description);

      throw error;
    });
  return response;
};

const editServiceExpireAPI = async ({ values }) => {
  const response = await apiInstance
    .put(`/user/parent/service-expire`, values)
    .then((response) => {
      if (response.error_code) {
        throw response;
      }

      return response;
    })
    .catch((error) => {
      console.log(error);
      message.error(error.error_description);
      throw error;
    });
  return response;
};

const editChildrenServiceExpireAPI = async ({ values }) => {
  const response = await apiInstanceV2
    .put(`/user/parent/service-expire`, values)
    .then((response) => {
      if (response.error_code) {
        throw response;
      }

      return response;
    })
    .catch((error) => {
      message.error(error.error_description);
      console.log('>>>>>>>>>>> editChildrenServiceExpireAPI: ', error);
      throw error;
    });
  return response;
};

export function* getEditMemberCredentials({ payload: { selectedID, values } }) {
  try {
    yield getItemEditAPI({ selectedID, values });
    yield put(updateItemEdit({ isLoading: false, status: true, isError: false }));
  } catch (error) {
    yield put(updateItemEdit({ isLoading: false, status: false, isError: true }));
  }
}

export function* editServiceExpireCredentials({ payload: { values } }) {
  try {
    yield editServiceExpireAPI({ values });
    yield put(setEditServiceExpire({ isLoading: false, status: true, isError: false }));
  } catch (error) {
    yield put(setEditServiceExpire({ isLoading: false, status: false, isError: true }));
  }
}

export function* editChildrenServiceExpireCredentials({ payload: { values } }) {
  try {
    yield put(setEditChildrenServiceExpire({ isLoading: true, status: false, isError: false }));
    yield editChildrenServiceExpireAPI({ values });
    yield put(setEditChildrenServiceExpire({ isLoading: false, status: true, isError: false }));
  } catch (error) {
    console.log('🚀 ~ function*editChildrenServiceExpireCredentials ~ error:', error);
    yield put(setEditChildrenServiceExpire({ isLoading: false, status: false, isError: true }));
  }
}

export function* onEditMemberStart() {
  yield takeLatest(types.GET_ITEM_EDIT, getEditMemberCredentials);
  yield takeLatest(types.EDIT_SERVICE_EXPIRE, editServiceExpireCredentials);
  yield takeLatest(types.EDIT_CHILDREN_SERVICE_EXPIRE, editChildrenServiceExpireCredentials);
}

export function* editMemberSagas() {
  yield all([call(onEditMemberStart)]);
}
