import types from './hospitalDetailActionTypes';

export const getHospitalDetail = (values) => ({
  type: types.GET_HOSPITAL_DETAIL,
  payload: values,
});

export const showHospitalDetail = (values) => ({
  type: types.SHOW_HOSPITAL_DETAIL,
  payload: values,
});
