import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
//import { registerStart } from "../../store/register/registerActions";
import { TextField } from '@mui/material';
import { Button } from '@mui/material';
import { getChangePassword } from '../../../store/user/changePassword/changePasswordActions';

const ChangePassword = () => {
  const dispatch = useDispatch();
  const [values, setValues] = useState(null);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = () => {
    if (values.current_password === '') {
      alert('Please enter field Password');
      return;
    }
    if (values.new_password === '') {
      alert('Please enter field New Password');
      return;
    }
    if (values.new_password_confirmation === '') {
      alert('Please enter field Confirm New Password');
      return;
    }

    if (values.new_password !== values.new_password_confirmation) {
      alert("Passwords don't match");
      return;
    }

    // Handle submit success
    dispatch(getChangePassword(values));
  };

  return (
    <>
      <div className="ChangePassword_main fs-15 pt-2 pb-2">
        <h3 className="fw-bold mb-3">Change Password</h3>
        <form className="" autoComplete="off" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-12 col-lg-6 mb-2">
              <TextField
                name="current_password"
                type={'password'}
                fullWidth
                required
                id="outlined-current_password"
                label="Current Password"
                onChange={handleChange}
              />
            </div>
            <div className="col-12 col-lg-6 mb-2">
              <TextField
                name="new_password"
                type={'password'}
                fullWidth
                required
                id="outlined-new_password"
                label="New Password"
                onChange={handleChange}
              />
            </div>
            <div className="col-12 col-lg-6 mb-2">
              <TextField
                name="new_password_confirmation"
                type={'password'}
                fullWidth
                required
                id="outlined-new_password_confirmation"
                label="New Password Confirmation"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="mw-930 mx-auto text-end">
            <Button
              color="primary"
              className="fw-bold"
              variant="contained"
              size="large"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};
export default ChangePassword;
