import { all, call, put, takeLatest } from 'redux-saga/effects';
import apiInstance from '../../../../gateway/Instance';

import { showMember } from '../memberActions';

import types from '../memberActionTypes';

const getMemberAPI = async ({ roleID, page, per_page, childrenType }) => {
  const response = apiInstance.get(`/user`, {
    params: {
      'filters[role]': roleID,
      page: page,
      per_page: per_page,
      'filters[children_type]': childrenType,
    },
  });
  return response;
};

export function* getMemberCredentials({ payload }) {
  try {
    const result = yield call(getMemberAPI, payload);
    yield put(showMember({ isLoading: false, data: result, isError: false }));
  } catch (error) {
    yield put(showMember({ isLoading: false, data: [], isError: true }));
  }
}

export function* onMemberStart() {
  yield takeLatest(types.GET_MEMBER, getMemberCredentials);
}

export function* memberSagas() {
  yield all([call(onMemberStart)]);
}
