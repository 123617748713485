import types from './deleteMemberActionTypes';

export const getItemDelete = (deleteID) => ({
  type: types.GET_ITEM_DELETE,
  payload: deleteID,
});

export const deleteUsers = (deleteID) => ({
  type: types.DELETE_USERS,
  payload: deleteID,
});

export const updateItemDelete = (deleteID) => ({
  type: types.UPDATE_ITEM_DELETE,
  payload: deleteID,
});
