/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//import { registerStart } from "../../store/register/registerActions";
import CloseIcon from '@mui/icons-material/Close';
import { DialogContent, DialogTitle, IconButton } from '@mui/material';
import { Button, Checkbox, Form, message, notification } from 'antd';
import FormInput from '../../../../components/ColFormItem/FormInput';
import { getMember } from '../../../../store/user/member/memberActions';
import {
  addMemberStart,
  addMemberSuccess,
} from '../../../../store/user/addMember/addMemberActions';
import LoadingWrapper from '../../../../components/LoadingCustom/LoadingWrapper';

const CreateDoctor = ({ pageSize, setLoadingList, setOpenAdd, handleClose }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const roleID = 6;

  const [loading, setLoading] = useState(false);

  const addMemberStatus = useSelector((state) => state.addMemberReducer.items);

  useEffect(() => {
    if (addMemberStatus.status === true) {
      setLoadingList(true);
      setLoading(false);
      setOpenAdd(false);

      dispatch(getMember({ roleID: roleID, page: 1, per_page: pageSize }));
      resetStatusAction();
    } else if (!addMemberStatus.status && addMemberStatus.isError) {
      setLoading(false);
      resetStatusAction();
    }
  }, [addMemberStatus]);

  const resetStatusAction = () => {
    return dispatch(addMemberSuccess({ isLoading: false, status: false, isError: false }));
  };

  const handleSubmit = (values) => {
    setLoading(true);

    const paramsSubmit = {
      ...values,
      role: roleID,
      user_verified: values.user_verified ? 1 : 0,
    };

    // Handle submit success
    dispatch(addMemberStart(paramsSubmit));
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
    notification.error({
      message: 'Error',
      description: 'Add new doctor failed!',
    });
  };

  return (
    <>
      <DialogTitle className="fw-bold">
        <span>Add New doctor</span>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <LoadingWrapper loading={loading}>
          <div className="custom-form fs-15 pt-2">
            <Form
              form={form}
              name="createDoctor"
              onFinish={handleSubmit}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <div className="row">
                <FormInput required label="Full Name" name="name" classCol="col-12 col-lg-6" />
                <FormInput
                  required
                  type="password"
                  label="Password"
                  name="password"
                  classCol="col-12 col-lg-6"
                />
                <FormInput required label="Phone" name="phone" classCol="col-12 col-lg-6" />
                <FormInput required label="Email" name="email" classCol="col-12 col-lg-6" />
                <FormInput required label="Address" name="address" classCol="col-12 col-lg-6" />
                <div className="col-12 col-lg-6">
                  <Form.Item name="user_verified" valuePropName="checked" className="mt-3">
                    <Checkbox>User Verified</Checkbox>
                  </Form.Item>
                </div>
              </div>
              <div className="mw-930 mx-auto text-end">
                <Form.Item>
                  <Button type="primary" className="fw-bold rounded" size="large" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
        </LoadingWrapper>
      </DialogContent>
    </>
  );
};
export default CreateDoctor;
