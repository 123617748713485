import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//import { registerStart } from "../../store/register/registerActions";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import CloseIcon from '@mui/icons-material/Close';
import { DialogContent, DialogTitle, IconButton, TextField } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { Button, Form, Space, message, notification } from 'antd';
import FormSelect from '../../../../components/ColFormItem/FormSelect';
import RepeatLocation from '../../../../components/DynamicFormItem/RepeatLocation';
import LoadingWrapper from '../../../../components/LoadingCustom/LoadingWrapper';
import { getDistrictsByProvince, getProvinces } from '../../../../store/address/addressActions';
import { getItemEdit, updateItemEdit } from '../../../../store/user/editMember/editMemberActions';
import { getMember } from '../../../../store/user/member/memberActions';
import '../../dashboard.scss';

const EditEmployee = ({ name, page, pageSize, setLoadingList, handleClose, setOpenEdit }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const roleID = 2;
  const optionsType = [
    { value: 1, label: 'Children' },
    { value: 2, label: 'People at risk of stroke' },
  ];
  const initialLocation = [
    {
      province_id: null,
      district_id: [],
    },
  ];

  const [memberDataDetail, setMemberDataDetail] = useState([]);
  const [checkedVal, setCheckedVal] = useState(false);
  const [optionsProvinces, setOptionsProvinces] = useState([]);
  const [loading, setLoading] = useState(true);

  const memDetailData = useSelector((state) => state.memberDetailReducer.items.data);
  const editStatus = useSelector((state) => state.editMemberReducer.items);
  const provinces = useSelector((state) => state.addressReducer.provinces);

  useEffect(() => {
    dispatch(getProvinces());
  }, []);

  useEffect(() => {
    if (memDetailData.error_code === 0) {
      const infoData = memDetailData.data;
      const rawDataProvinces = infoData?.user_info?.provinces || [];
      const rawDataDistricts = infoData?.user_info?.districts || [];
      const provincesId = rawDataProvinces?.map((item) => item.id);
      const districtsId = rawDataDistricts?.map((item) => item.id);

      if (rawDataDistricts?.length > 0 && provincesId?.length > 0) {
        dispatch(getDistrictsByProvince({ province_ids: provincesId }));
      }

      setMemberDataDetail({ ...infoData, provinces: provincesId, districts: districtsId });

      const locations = [];

      for (const province of rawDataProvinces) {
        let locationTemp = { province_id: province.id, district_id: [] };
        for (const district of rawDataDistricts) {
          if (province.id === district.province_id) {
            locationTemp = {
              ...locationTemp,
              district_id: [...locationTemp.district_id, district.id],
            };
          }
        }

        locations.push(locationTemp);
      }

      form.setFieldsValue({
        name: infoData.name,
        address: infoData.address,
        phone: infoData.phone,
        email: infoData.email,
        password: infoData.password,
        location: locations.length ? locations : initialLocation,
        type: infoData.user_info?.type[0]?.id || null,
      });

      infoData?.user_verified ? setCheckedVal(true) : setCheckedVal(false);

      setLoading(false);
    } else if (memDetailData.error_code === 1) {
      setLoading(false);
      handleClose();
    }
  }, [memDetailData]);

  useEffect(() => {
    if (editStatus.status === true) {
      setLoadingList(true);
      setLoading(false);
      setOpenEdit(false);

      dispatch(getMember({ roleID: roleID, page: page, per_page: pageSize }));
      dispatch(updateItemEdit({ isLoading: false, status: false, isError: false }));

      message.success('Updated user success!');
    } else if (!editStatus.status && editStatus.isError) {
      setOpenEdit(false);
      message.error('Update user failed!');
    }
  }, [editStatus]);

  useEffect(() => {
    if (provinces.data.length > 0) {
      const optionsProvincesTemp = provinces.data?.map((item) => ({
        label: item.name,
        value: item.id,
      }));

      setOptionsProvinces(optionsProvincesTemp);
    } else {
      setOptionsProvinces([]);
    }
  }, [provinces]);

  const handleChangeChecked = (event) => {
    setCheckedVal(event.target.checked);
  };

  const handleSubmit = (values) => {
    if (!values.name) {
      alert('Please enter field FullName');
      return;
    } else if (values?.location?.length <= 0) {
      alert('Please enter field Locations');
      return;
    } else if (!values.type) {
      alert('Please select field Type');
      return;
    }

    setLoading(true);

    const paramsSubmit = {
      name: values.name,
      password: values.password,
      address: values.address,
      type: values.type,
      location: values.location,
      user_verified: checkedVal ? '1' : '0',
    };

    // Handle submit success
    dispatch(
      getItemEdit({
        selectedID: memberDataDetail?.id,
        values: paramsSubmit,
      })
    );
  };

  const onFinishFailed = (errorInfo) => {
    notification.error({
      message: 'Error',
      description: 'Edit employee failed!',
    });
  };

  const reRenderOptionsProvinces = () => {
    const location = form.getFieldValue('location') || [];
    const provincesTemp = location?.map((item) => item?.province_id) || [];
    const selectedProvincesTemp = [...new Set(provincesTemp)];

    const resultTemp = optionsProvinces.map((item) => {
      if (selectedProvincesTemp.includes(item.value)) {
        item.disabled = true;
      } else {
        item.disabled = false;
      }

      return item;
    });

    setOptionsProvinces(resultTemp);
  };

  return (
    <>
      <DialogTitle className="fw-bold">
        <span>Edit Employee {name}</span>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <LoadingWrapper loading={loading}>
          <div className="addNewEmployee_main fs-15 pt-2 pb-2">
            <Form
              form={form}
              name="updateEmployee"
              onFinish={handleSubmit}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <div className="row">
                <div className="col-12 col-lg-6">
                  <Form.Item name="name">
                    <TextField
                      name="name"
                      fullWidth
                      required
                      id="outlined-fullname"
                      label="Fullname"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Form.Item>
                </div>
                <div className="col-12 col-lg-6">
                  <Form.Item name="password">
                    <TextField
                      name="password"
                      type={'password'}
                      autoComplete="new-password"
                      fullWidth
                      // required
                      id="outlined-password"
                      label="New Password"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Form.Item>
                </div>
                <div className="col-12 col-lg-6">
                  <Form.Item name="email">
                    <TextField
                      name="email"
                      fullWidth
                      required
                      id="outlined-email"
                      label="Email"
                      disabled
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Form.Item>
                </div>
                <div className="col-12 col-lg-6">
                  <Form.Item name="phone">
                    <TextField
                      name="phone"
                      fullWidth
                      required
                      id="outlined-phone"
                      label="Phone"
                      disabled
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Form.Item>
                </div>
                <div className="col-12 col-lg-6">
                  <Form.Item name="address">
                    <TextField
                      name="address"
                      fullWidth
                      id="outlined-address"
                      label="Address"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Form.Item>
                </div>

                <div className="col-12 col-lg-6 mt-1">
                  <Form.Item name="user_verified">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={!!checkedVal}
                            onChange={handleChangeChecked}
                            name="user_verified"
                          />
                        }
                        label="User Verified"
                      />
                    </FormGroup>
                  </Form.Item>
                </div>

                <FormSelect
                  required
                  label="Type"
                  name="type"
                  options={optionsType}
                  classCol="col-12 col-lg-6"
                  classNameSelect="ant-select-custom"
                />

                <div className="col-12 col-lg-6" />

                <div className="col-12">
                  <Form.List name="location">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }) => (
                          <Space key={key} className="w-100 d-block" align="baseline">
                            <div className="row">
                              <RepeatLocation
                                form={form}
                                isUpdate
                                restField={restField}
                                name={name}
                                optionsProvinces={optionsProvinces}
                              />
                              <div className="col-12 col-md-1 p-0 mt-0 mt-md-2 mb-2 mb-md-0">
                                <div className="h-75 d-flex align-items-center justify-content-center">
                                  <MinusCircleOutlined
                                    onClick={() => {
                                      remove(name);
                                      reRenderOptionsProvinces();
                                      return;
                                    }}
                                    className="text-red"
                                  />
                                </div>
                              </div>
                            </div>
                          </Space>
                        ))}
                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            icon={<PlusOutlined />}
                            className="d-flex justify-content-center align-items-center rounded"
                          >
                            Add Locations
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </div>
              </div>
              <div className="mw-930 mx-auto text-end">
                <Button type="primary" className="fw-bold rounded" size="large" htmlType="submit">
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        </LoadingWrapper>
      </DialogContent>
    </>
  );
};
export default EditEmployee;
