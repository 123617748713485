import { Form, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDistrictsByProvince } from '../../../store/address/addressActions';

const RepeatLocation = ({ form, restField, name, optionsProvinces = [], isUpdate = false }) => {
  const dispatch = useDispatch();

  const [valueProvinces, setValueProvinces] = useState(null);
  const [optionsDistrict, setOptionsDistrict] = useState([]);

  const districtsProvince = useSelector((state) => state.addressReducer.districtsProvince);

  useEffect(() => {
    if (!districtsProvince?.data?.length) {
      return;
    }

    let provinceId = null;

    const resultDistrict = districtsProvince?.data?.map((item) => {
      provinceId = item.province_id;

      return {
        label: item.name,
        value: item.id,
        provinceId: item.province_id,
      };
    });

    if (provinceId === valueProvinces) {
      return setOptionsDistrict(resultDistrict);
    } else if (isUpdate && form) {
      const location = form.getFieldValue('location');

      if (!location[name]) {
        return;
      }

      const provincesTemp = location?.map((item) => item?.province_id) || [];
      const selectedProvincesTemp = [...new Set(provincesTemp)];
      handleRenderProvinces(selectedProvincesTemp);

      const resultOptionDistrict = resultDistrict.filter(
        (item) => item.provinceId === location[name].province_id
      );

      if (!resultOptionDistrict?.length) {
        return;
      }

      return setOptionsDistrict(resultOptionDistrict);
    }
  }, [districtsProvince]);

  const handleRenderProvinces = (arraySelected = []) => {
    optionsProvinces.map((item) => {
      if (arraySelected.includes(item.value)) {
        item.disabled = true;
      } else {
        item.disabled = false;
      }

      return item;
    });

    return;
  };

  const handleChangeProvince = (value) => {
    const selectedProvinceTemp =
      form.getFieldValue('location')?.map((item) => item?.province_id) || [];
    const selectedTemp = [...new Set(selectedProvinceTemp)];
    handleRenderProvinces(selectedTemp);

    if (value) {
      // form.re
      setValueProvinces(value);

      // let fieldValueLocation = form.getFieldValue('location');
      // fieldValueLocation = fieldValueLocation.map((item) => item?.province_id);

      // const provincesTemp = [...new Set(fieldValueLocation)];
      dispatch(getDistrictsByProvince({ province_ids: [value] }));
    }
  };

  return (
    <>
      <div className="col-12 col-md-6">
        <label className="mb-sm">
          Province<span className="text-red"> *</span>
        </label>
        <Form.Item
          {...restField}
          name={[name, 'province_id']}
          rules={[
            {
              required: true,
              message: 'Missing province',
            },
          ]}
        >
          <Select
            showSearch
            placeholder="Select a province"
            optionFilterProp="children"
            // loading={provinces.isLoading}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={optionsProvinces}
            className="ant-select-custom"
            onChange={handleChangeProvince}
          />
        </Form.Item>
      </div>
      <div className="col-12 col-md-5">
        <label className="mb-sm">
          Districts<span className="text-red"> *</span>
        </label>
        <Form.Item
          {...restField}
          name={[name, 'district_id']}
          rules={[
            {
              required: true,
              message: 'Missing districts',
            },
          ]}
        >
          <Select
            showSearch
            allowClear
            mode="multiple"
            placeholder="Select districts"
            // loading={districtsProvince.isLoading}
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={optionsDistrict}
            className="ant-select-custom"
          />
        </Form.Item>
      </div>
    </>
  );
};

export default RepeatLocation;
