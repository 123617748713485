import { INITIAL_STATE_REDUCER } from '../../constants/Constants';
import types from './packageActionTypes';

const initialState = {
  packages: INITIAL_STATE_REDUCER.OBJECT_LOADING,
  packageTypes: INITIAL_STATE_REDUCER.ARRAY_LOADING,
  packageDetail: INITIAL_STATE_REDUCER.OBJECT,
};

const packageReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_PACKAGES:
      return { ...state, packages: action.values };
    case types.SET_PACKAGE_DETAIL:
      return { ...state, packageDetail: action.values };
    case types.SET_PACKAGE_TYPES:
      return { ...state, packageTypes: action.values };
    default:
      return state;
  }
};

export default packageReducer;
