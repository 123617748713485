import types from './memberDetailActionTypes';

const initialState = {
  items: { isLoading: true, data: [], isError: false },
};

const memberDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_MEMBER_DETAIL:
      return {
        ...state,
        items: action.payload,
      };
    default:
      return state;
  }
};

export default memberDetailReducer;
