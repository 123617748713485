import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import MainDashBoard from './MainDashBoard';
import Employee from './Employee';
import Supervisor from './Supervisor';
import Doctor from './Doctor';
import Bodyguard from './Bodyguard';
import Hospital from './Hospital';
import HealthRecords from './HealthRecords';
import TrackingArea from './TrackingArea';
import TrackingDevices from './TrackingDevices';
import Setting from './Setting';
import PackageSetting from './PackageSetting';
import Notification from './Notification';
import SideBar from '../../components/Sidebar';
import Header from '../../components/Header';
import NotFound from '../../components/NotFound';
import Children from './Children';
import ChangePassword from './ChangePassword';
import './dashboard.scss';
import Histories from './Order/Histories';
import EmployeeManagement from './EmployeeManagement';
import { useSelector } from 'react-redux';
import { objectIsEmpty } from '../../utils/supportFunction';
import PatientsExaminationHistory from './PatientsExaminationHistory';
import ExaminationHistory from './PatientsExaminationHistory/ExaminationHistory';
import Packages from './Packages';

const DashBoard = () => {
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  const profileUserLogin = useSelector((state) => state.profileReducer.items);

  useEffect(() => {
    if (!profileUserLogin.isLoading && !objectIsEmpty(profileUserLogin.data?.data)) {
      return profileUserLogin.data?.data?.user_info?.is_super_admin
        ? setIsSuperAdmin(true)
        : setIsSuperAdmin(false);
    }
  }, [profileUserLogin]);

  return (
    <>
      <div className="d-flex flex-wrap justify-content-between align-items-center mb-25px">
        <Header />
      </div>
      <div className="dashboard-main pb-3">
        <div className="dashboard-main_sidebar position-fixed start-0 top-0 shadow bg-primary h-100">
          <SideBar />
        </div>
        <div className="px-3 pt-2 dashboard-main_content pt-7">
          <Routes>
            <Route path="/" element={<MainDashBoard />}></Route>
            {isSuperAdmin && (
              <Route path="/employee-management" element={<EmployeeManagement />}></Route>
            )}
            <Route path="/employee" element={<Employee />}></Route>
            <Route path="/supervisor" element={<Supervisor />}></Route>
            <Route path="/children" element={<Children />}></Route>
            <Route path="/doctor" element={<Doctor />}></Route>
            <Route path="/bodyguard" element={<Bodyguard />}></Route>
            <Route path="/hospital" element={<Hospital />}></Route>
            <Route path="/health-records" element={<HealthRecords />}></Route>
            <Route path="/area-tracking" element={<TrackingArea />}></Route>
            <Route path="/devices-tracking" element={<TrackingDevices />}></Route>
            <Route path="/setting" element={<Setting />}></Route>
            <Route path="/package-setting" element={<PackageSetting />}></Route>
            <Route path="/notification" element={<Notification />}></Route>
            <Route path="/change-password" element={<ChangePassword />}></Route>
            <Route path="/histories" element={<Histories />}></Route>
            <Route path="/examination-history">
              <Route index element={<PatientsExaminationHistory />} />
              <Route path=":id" element={<ExaminationHistory />} />
            </Route>
            <Route path="/package" element={<Packages />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </div>
    </>
  );
};
export default DashBoard;
