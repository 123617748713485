import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//import { registerStart } from "../../store/register/registerActions";
import CloseIcon from '@mui/icons-material/Close';
import { DialogContent, DialogTitle, IconButton, TextField } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { Button, Form, Select, message } from 'antd';
import { getProvinces } from '../../../../store/address/addressActions';
import { getItemEdit, updateItemEdit } from '../../../../store/user/editMember/editMemberActions';
import { getMember } from '../../../../store/user/member/memberActions';
import '../../dashboard.scss';
import LoadingWrapper from '../../../../components/LoadingCustom/LoadingWrapper';

const EditEmployeeManagement = ({ name, page, pageSize, setLoadingList, handleClose, setOpenEdit }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const roleID = 1;

  const [memberDataDetail, setMemberDataDetail] = useState({});
  const [checkedVerify, setCheckedVerify] = useState(false);
  const [checkedIsSuperAdmin, setCheckedIsSuperAdmin] = useState(false);
  const [loading, setLoading] = useState(true);

  const memDetailData = useSelector((state) => state.memberDetailReducer.items.data);
  const editStatus = useSelector((state) => state.editMemberReducer.items);
  const provinces = useSelector((state) => state.addressReducer.provinces);

  useEffect(() => {
    dispatch(getProvinces());
  }, []);

  useEffect(() => {
    if (memDetailData.error_code === 0) {
      const infoData = memDetailData.data;

      const provincesId = infoData.provinces?.map((item) => item.id);

      setMemberDataDetail({ ...infoData, provinces: provincesId });

      form.setFieldsValue({
        name: infoData.name,
        address: infoData.address,
        phone: infoData.phone,
        email: infoData.email,
        password: infoData.password,
        provinces: provincesId,
        isSuperAdmin: infoData.user_info.is_super_admin,
      });

      infoData?.user_verified ? setCheckedVerify(true) : setCheckedVerify(false);
      infoData?.user_info?.is_super_admin
        ? setCheckedIsSuperAdmin(true)
        : setCheckedIsSuperAdmin(false);

      setLoading(false);
    } else if (memDetailData.error_code === 1) {
      setLoading(false);
      handleClose();
    }
  }, [memDetailData]);

  useEffect(() => {
    if (editStatus.status === true) {
      setLoadingList(true);
      setLoading(false);
      setOpenEdit(false);

      dispatch(getMember({ roleID: roleID, page: page, per_page: pageSize }));
      dispatch(updateItemEdit({ isLoading: false, status: false, isError: false }));

      message.success('Updated user success!');
    } else if (!editStatus.status && editStatus.isError) {
      setOpenEdit(false);
      message.error('Update user failed!');
    }
  }, [editStatus]);

  const renderOptionsProvinces = useMemo(() => {
    return provinces.data.map((item) => ({
      label: item.name,
      value: item.id,
    }));
  }, [provinces]);

  const handleCheckIsSuperAdmin = (event) => {
    setCheckedIsSuperAdmin(event.target.checked);
  };

  const handleCheckVerified = (event) => {
    setCheckedVerify(event.target.checked);
  };

  const handleSubmit = (values) => {
    if (!values.name) {
      alert('Please enter field FullName');
      return;
    }

    if (values?.provinces?.length <= 0) {
      alert('Please enter field Provinces');
      return;
    }

    setLoading(true);

    const paramsSubmit = {
      name: values.name,
      password: values.password,
      address: values.address,
      user_verified: checkedVerify ? 1 : 0,
      admin_info: { is_super_admin: checkedIsSuperAdmin ? 1 : 0 },
    };

    if (values.provinces?.length > 0) {
      const locations = values.provinces.map((item) => ({ province_id: item }));
      paramsSubmit.location = locations;
    }

    // Handle submit success
    dispatch(
      getItemEdit({
        selectedID: memberDataDetail?.id,
        values: paramsSubmit,
      })
    );
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      <DialogTitle className="fw-bold">
        <span>Edit Employee {name}</span>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <LoadingWrapper loading={loading}>
          <div className="addNewEmployee_main fs-15 pt-2 pb-2">
            <Form
              form={form}
              name="updateEmployee"
              onFinish={handleSubmit}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <div className="row">
                <div className="col-12 col-lg-6">
                  <Form.Item name="name">
                    <TextField
                      name="name"
                      fullWidth
                      required
                      id="outlined-fullname"
                      label="Fullname"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Form.Item>
                </div>
                <div className="col-12 col-lg-6">
                  <Form.Item name="password">
                    <TextField
                      name="password"
                      type={'password'}
                      autoComplete="new-password"
                      fullWidth
                      // required
                      id="outlined-password"
                      label="New Password"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Form.Item>
                </div>
                <div className="col-12 col-lg-6">
                  <Form.Item name="email">
                    <TextField
                      name="email"
                      fullWidth
                      required
                      id="outlined-email"
                      label="Email"
                      disabled
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Form.Item>
                </div>
                <div className="col-12 col-lg-6">
                  <Form.Item name="phone">
                    <TextField
                      name="phone"
                      fullWidth
                      required
                      id="outlined-phone"
                      label="Phone"
                      disabled
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Form.Item>
                </div>
                <div className="col-12 col-lg-6">
                  <Form.Item name="address">
                    <TextField
                      name="address"
                      fullWidth
                      id="outlined-address"
                      label="Address"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Form.Item>
                </div>

                <div className="col-12 col-md-6">
                  <Form.Item
                    name={'provinces'}
                    rules={[
                      {
                        required: true,
                        message: 'Missing province',
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      mode="multiple"
                      label={'Provinces'}
                      placeholder="Select a province *"
                      optionFilterProp="children"
                      loading={provinces.isLoading}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={renderOptionsProvinces}
                      className="ant-select-custom"
                    />
                  </Form.Item>
                </div>

                <div className="col-12 col-lg-6">
                  <Form.Item name="isSuperAdmin">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={!!checkedIsSuperAdmin}
                            onChange={handleCheckIsSuperAdmin}
                            name="isSuperAdmin"
                          />
                        }
                        label="Is super admin"
                      />
                    </FormGroup>
                  </Form.Item>
                </div>

                <div className="col-12 col-lg-6">
                  <Form.Item name="user_verified">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={!!checkedVerify}
                            onChange={handleCheckVerified}
                            name="user_verified"
                          />
                        }
                        label="User Verified"
                      />
                    </FormGroup>
                  </Form.Item>
                </div>
              </div>
              <div className="mw-930 mx-auto text-end">
                <Button type="primary" className="fw-bold rounded" size="large" htmlType="submit">
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        </LoadingWrapper>
      </DialogContent>
    </>
  );
};
export default EditEmployeeManagement;
