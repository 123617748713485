import React from 'react';
import { Form, Input, InputNumber } from 'antd';
const { TextArea, Password } = Input;

const FormInput = ({
  type,
  label,
  name,
  placeholder,
  disabled,
  classCol,
  classNamesInput = '',
  required,
  rules,
  size,
  propFI,
  propInput,
}) => {
  const classNameDefault = `rounded ${classNamesInput}`;

  // Format Money
  const formatMoney = (value) => {
    if (!value) {
      return '';
    }

    return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const parserMoney = (value) => {
    if (!value) {
      return '';
    }

    return `${value}`.replace(/\$\s?|(,*)/g, '');
  };

  const typeInput = () => {
    switch (type) {
      case 'textArea':
        return (
          <TextArea
            placeholder={placeholder ?? placeholder}
            disabled={disabled ?? false}
            className={classNameDefault}
            {...propInput}
          />
        );
      case 'number':
        return (
          <InputNumber
            size={size}
            formatter={(value) => formatMoney(value)}
            parser={(value) => parserMoney(value)}
            placeholder="Input Number"
            className={classNameDefault}
            {...propInput}
          />
        );
      case 'password':
        return (
          <Password
            size={size}
            placeholder={placeholder ?? placeholder}
            disabled={disabled ?? false}
            className={`${classNameDefault} pt-0 pb-0 ps-0`}
            {...propInput}
          />
        );
      default:
        return (
          <Input
            size={size}
            placeholder={placeholder ?? placeholder}
            disabled={disabled ?? false}
            className={classNameDefault}
            {...propInput}
          />
        );
    }
  };

  return (
    <div className={classCol}>
      <label className="mb-sm">
        {label}:{required ? <span className="text-red"> *</span> : ''}
      </label>
      <Form.Item
        name={name}
        rules={
          rules ?? [
            {
              required: required ?? false,
              message: `${label} field is required!`,
            },
          ]
        }
        {...propFI}
      >
        {typeInput()}
      </Form.Item>
    </div>
  );
};

export default FormInput;
