import types from './memberDetailActionTypes';

export const getMemberDetail = (values) => ({
  type: types.GET_MEMBER_DETAIL,
  payload: values,
});

export const showMemberDetail = (values) => ({
  type: types.SHOW_MEMBER_DETAIL,
  payload: values,
});
