import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { Checkbox, Form, notification } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingWrapper from '../../../../components/LoadingCustom/LoadingWrapper';
import { createPackageAPI } from '../../../../store/package/packageSagas';
import { getPackages } from '../../../../store/package/packageActions';
import FormInput from '../../../../components/ColFormItem/FormInput';
import FormSelect from '../../../../components/ColFormItem/FormSelect';

const CreatePackage = ({ showModal, setShowModal }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const packageTypesReducer = useSelector((state) => state.packageReducer.packageTypes);

  const renderOptionsType = () => {
    if (!packageTypesReducer.data.length) {
      return [];
    }

    const result = packageTypesReducer.data.map((item, index) => ({
      value: item.value,
      label: item.name,
    }));

    return result;
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSubmit = async (values) => {
    await createPackageAPI(values)
      .then((res) => {
        if (res.error_code === 0) {
          dispatch(getPackages());

          setShowModal(false);
          notification.success({
            message: 'Success',
            description: 'Successfully created package!',
          });
        } else {
          notification.error({
            message: 'Error',
            description: 'Create package failed!',
          });
        }
      })
      .catch((error) => {
        console.log('🚀 ~ file: index.js:61 ~ handleSubmit ~ error:', error);
        notification.error({
          message: 'Error',
          description: 'Create package failed!',
        });
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('🚀 ~ file: index.js:70 ~ onFinishFailed ~ errorInfo:', errorInfo);

    notification.error({
      message: 'Error',
      description: 'Create package failed!',
    });
  };

  return (
    <Dialog open={showModal} maxWidth={'md'} fullWidth={true} onClose={handleCloseModal}>
      <DialogTitle className="fw-bold">
        <span>Add New Package</span>
        <IconButton
          aria-label="close"
          onClick={handleCloseModal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <LoadingWrapper loading={packageTypesReducer.isLoading}>
          <div className="custom-form fs-15 pt-2">
            <Form
              form={form}
              name="updatePackage"
              onFinish={handleSubmit}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <div className="row">
                <FormInput required label="Name" name="name" classCol="col-12 col-lg-6" />
                <FormInput
                  type="number"
                  required
                  label="Price"
                  name="price"
                  classCol="col-12 col-lg-6"
                />
                <FormInput
                  type="number"
                  required
                  label="Value"
                  name="value"
                  classCol="col-12 col-lg-6"
                />
                <FormSelect
                  required
                  label="Type"
                  name="product_type"
                  options={renderOptionsType()}
                  classCol="col-12 col-lg-6"
                />

                <div className="col-12 col-lg-6">
                  <Form.Item name="is_free_trial" valuePropName="checked">
                    <Checkbox>Free trial</Checkbox>
                  </Form.Item>
                </div>
              </div>
              <div className="mw-930 mx-auto text-end">
                <Button type="primary" className="fw-bold rounded" size="large" htmlType="submit">
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        </LoadingWrapper>
      </DialogContent>
    </Dialog>
  );
};

export default CreatePackage;
