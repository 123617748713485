import types from './addHospitalActionTypes';

const initialState = {
  items: { isLoading: true, status: false, isError: false },
};

const addHospitalReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_HOSPITAL_SUCCESS:
      return {
        ...state,
        items: action.payload,
      };
    case types.ADD_HOSPITAL_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default addHospitalReducer;
