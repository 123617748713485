import types from './deleteHospitalActionTypes';

export const getHospitalDelete = (deleteID) => ({
  type: types.GET_HOSPITAL_DELETE,
  payload: deleteID,
});

export const updateHospitalDelete = (deleteID) => ({
  type: types.UPDATE_HOSPITAL_DELETE,
  payload: deleteID,
});
