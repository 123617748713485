import types from "./addMemberActionTypes";

export const addMemberStart = (values) => ({
  type: types.ADD_MEMBER_START,
  payload: values,
});

export const addMemberSuccess = (values) => ({
  type: types.ADD_MEMBER_SUCCESS,
  payload: values,
});

export const addMemberFailure = (error) => ({
  type: types.ADD_MEMBER_FAILURE,
  payload: error,
});
