import React from "react";
import { Link } from "react-router-dom";
import MainLogo from "../../images/logo.png";
import MenuIcon from "@mui/icons-material/Menu";
const Logo = () => {
  return (
    <>
      <div className="d-flex pt-2">
        <MenuIcon className="me-sm fs-2" />
        <Link className="text-center d-inline-block" to={"/"}>
          <img width={"130px"} src={MainLogo} alt="GGI" />
        </Link>
      </div>
    </>
  );
};
export default Logo;
