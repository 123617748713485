import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import LinearProgress from '@mui/material/LinearProgress';

export default function MuiTable({
  columns,
  rows,
  checkboxSelection,
  autoHeight,
  rowID,
  handleAddNewRow,
  handleEditRow,
  handleDeleteRow,
  showAddButton,
  showEditButton,
  showDeleteButton,
  singleSelected,
  rowsPerPageOptions,
  setSelectedID,
  setSelectedRows,
  pagination,
  page,
  pageSize,
  loading,
  paginationMode,
  onPageSizeChange,
  onPageChange,
  rowCount,
  showFirstButton,
  showLastButton,
  labelRowsPerPage,
  moreButtonRight,
  gridStyle,
}) {
  const [btnDisable, setBtnDisable] = useState(true);
  const [btnDeleteDisable, setBtnDeleteDisable] = useState(true);
  const [selectionModel, setSelectionModel] = useState([]);
  const [pageSizeNumber, setPageSizeNumber] = useState(pageSize);
  const [pageNumber, setSizeNumber] = useState(page);

  const handleRowSelected = (selectionId) => {
    if (singleSelected) {
      setSelectionModel((prevModel) => selectionId.filter((newId) => !prevModel.includes(newId)));
    } else {
      setSelectionModel(selectionId);
    }
    selectionId.length > 0 && selectionId.length < 2 ? setBtnDisable(false) : setBtnDisable(true);
    selectionId.length > 0 ? setBtnDeleteDisable(false) : setBtnDeleteDisable(true);
    const selectedIDs = new Set(selectionId);
    const selectedRows = rows.filter((row) => selectedIDs.has(row.id));
    setSelectedRows(selectedRows);
    setSelectedID(selectionId);
  };

  return (
    <div className="muiTable">
      <div className="d-flex justify-content-between mb-1">
        {showAddButton && (
          <Button variant="contained" startIcon={<AddIcon />} onClick={handleAddNewRow}>
            Add New
          </Button>
        )}
        <div className="d-flex">
          {moreButtonRight && moreButtonRight}
          {showEditButton && (
            <Button
              className="me-1"
              variant="contained"
              disabled={btnDisable}
              color="secondary"
              startIcon={<EditIcon />}
              onClick={handleEditRow}
            >
              Edit
            </Button>
          )}
          {showDeleteButton && (
            <Button
              variant="contained"
              color="error"
              disabled={btnDeleteDisable}
              startIcon={<DeleteIcon />}
              onClick={handleDeleteRow}
            >
              Delete
            </Button>
          )}
        </div>
      </div>
      <div style={gridStyle}>
        <DataGrid
          autoHeight={autoHeight}
          rows={rows}
          columns={columns}
          checkboxSelection={checkboxSelection}
          labelRowsPerPage={labelRowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          onPageSizeChange={onPageSizeChange}
          pagination={pagination}
          getRowId={(row) => row[rowID]}
          onSelectionModelChange={setSelectedRows && handleRowSelected}
          loading={loading}
          onPageChange={onPageChange}
          components={{
            LoadingOverlay: LinearProgress,
          }}
          page={page}
          pageSize={pageSize}
          paginationMode={paginationMode}
          rowCount={rowCount}
          showFirstButton={showFirstButton}
          showLastButton={showLastButton}
        />
      </div>
    </div>
  );
}
