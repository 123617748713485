import types from './searchUsersActionTypes';

export const searchUsers = (values) => ({
  type: types.SEARCH_USERS,
  payload: values,
});

export const showSearchUsers = (values) => ({
  type: types.SHOW_SEARCH_USERS,
  payload: values,
});

export const setSelectedUser = (values) => ({
  type: types.SET_SELECTED_USER,
  payload: values,
});

export const setChildrenType = (values) => ({
  type: types.SET_CHILDREN_TYPE,
  payload: values,
});
