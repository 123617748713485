import { combineReducers } from 'redux';

import auth from './auth/authReducer';
import memberReducer from './user/member/memberReducer';
import deleteReducer from './user/deleteMember/deleteMemberReducer';
import addMemberReducer from './user/addMember/addMemberReducer';
import editMemberReducer from './user/editMember/editMemberReducer';
import memberDetailReducer from './user/memberDetail/memberDetailReducer';
import profileReducer from './profile/profileReducer';
import hospitalReducer from './hospital/hospitalList/hospitalReducer';
import hospitalDetailReducer from './hospital/hospitalDetail/hospitalDetailReducer';
import editHospitalReducer from './hospital/editHospital/editHospitalReducer';
import deleteHospitalReducer from './hospital/deleteHospital/deleteHospitalReducer';
import addHospitalReducer from './hospital/addHospital/addHospitalReducer';
import changePasswordReducer from './user/changePassword/changePasswordReducer';
import addressReducer from './address/addressReducer';
import orderReducer from './order/orderReducer';
import searchUsersReducer from './user/searchUsers/searchUsersReducer';
import examinationHistoryReducer from './examinationHistory/examinationHistoryReducer';
import packageReducer from './package/packageReducer';

export default combineReducers({
  auth,
  memberReducer,
  deleteReducer,
  addMemberReducer,
  editMemberReducer,
  memberDetailReducer,
  profileReducer,
  hospitalReducer,
  hospitalDetailReducer,
  editHospitalReducer,
  deleteHospitalReducer,
  addHospitalReducer,
  changePasswordReducer,
  addressReducer,
  orderReducer,
  searchUsersReducer,
  examinationHistoryReducer,
  packageReducer,
});
