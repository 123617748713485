import types from './orderActionTypes';

export const getHistories = (values) => ({
  type: types.GET_HISTORIES,
  payload: values,
});

export const setHistories = (values) => ({
  type: types.SET_HISTORIES,
  payload: values,
});
