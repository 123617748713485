import { all, call, put, takeLatest } from 'redux-saga/effects';
import apiInstance from '../../../../gateway/Instance';
import { message } from 'antd';

import { addHospitalSuccess } from '../addHospitalActions';

import types from '../addHospitalActionTypes';

const addHospitalAPI = async (values) => {
  const response = await apiInstance
    .post(`/hospital`, values)
    .then((res) => {
      message.success('Created Hospital success');
    })
    .catch((error) => console.log(error));
  return response;
};

export function* addHospitalWithCredentials({ payload: values }) {
  try {
    yield put(addHospitalSuccess({ isLoading: true, status: false, isError: false }));
    yield addHospitalAPI(values);
    yield put(addHospitalSuccess({ isLoading: false, status: true, isError: false }));
  } catch (error) {
    yield put(addHospitalSuccess({ isLoading: false, status: false, isError: true }));
  }
}

export function* onAddHospitalStart() {
  yield takeLatest(types.ADD_HOSPITAL_START, addHospitalWithCredentials);
}

export function* addHospitalSagas() {
  yield all([call(onAddHospitalStart)]);
}
