import { all, call, put, takeEvery } from 'redux-saga/effects';
import types from './examinationHistoryActionTypes';
import apiInstance from '../../gateway/Instance';
import { INITIAL_STATE_REDUCER } from '../../constants/Constants';
import { setExaminationHistories, setExaminationHistoryDetail } from './examinationHistoryActions';

// ============ API ============

const getExaminationHistoriesAPI = async (params) => {
  const response = await apiInstance.get('/medical-examinations', { params });
  return response;
};

const getExaminationHistoryDetailAPI = async (id) => {
  const response = await apiInstance.get(`/medical-examinations/${id}`);
  return response;
};

export const createExaminationHistoryAPI = async () => {
  const response = await apiInstance.post('/');
  return response;
};

export const updateExaminationHistoryAPI = async ({ id, params }) => {
  const response = await apiInstance.put(`/medical-examinations/${id}`, params);
  return response;
};

export const deleteExaminationHistoryAPI = async () => {
  const response = await apiInstance.delete('/');
  return response;
};

// ============ Call API ============

function* getExaminationHistoriesCredentials({ values }) {
  try {
    yield put(setExaminationHistories(INITIAL_STATE_REDUCER.OBJECT_LOADING));
    const result = yield call(getExaminationHistoriesAPI, values);
    yield put(setExaminationHistories({ ...INITIAL_STATE_REDUCER.OBJECT, data: result.results }));
  } catch (error) {
    console.log('getExaminationHistoriesCredentials Error: ', error);
    yield put(setExaminationHistories(INITIAL_STATE_REDUCER.OBJECT_ERROR));
  }
}

function* getExaminationHistoryDetailCredentials({ values }) {
  try {
    yield put(setExaminationHistoryDetail(INITIAL_STATE_REDUCER.OBJECT_LOADING));
    const result = yield call(getExaminationHistoryDetailAPI, values);
    yield put(setExaminationHistoryDetail({ ...INITIAL_STATE_REDUCER.OBJECT, data: result.data }));
  } catch (error) {
    console.log('getExaminationHistoryDetailCredentials Error: ', error);
    yield put(setExaminationHistoryDetail(INITIAL_STATE_REDUCER.OBJECT_ERROR));
  }
}

function* onExaminationHistoryStart() {
  yield takeEvery(types.GET_EXAMINATION_HISTORIES, getExaminationHistoriesCredentials);
  yield takeEvery(types.GET_EXAMINATION_HISTORY_DETAIL, getExaminationHistoryDetailCredentials);
}

export function* examinationHistorySagas() {
  yield all([call(onExaminationHistoryStart)]);
}
