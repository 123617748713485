import React, { useEffect, useMemo, useState } from 'react';
import MuiTable from '../../../../components/MuiTable';
import { useDispatch, useSelector } from 'react-redux';
import { getHistories } from '../../../../store/order/orderActions';
import { formatDate } from '../../../../utils/supportFunction';

const Histories = () => {
  const dispatch = useDispatch();

  let accessToken = localStorage.getItem('access_token');

  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(null);
  const [pageSize, setPageSize] = useState(null);
  const [rowCount, setRowCount] = useState(0);
  const [selectedID, setSelectedID] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const histories = useSelector((state) => state.orderReducer.histories);

  useEffect(() => {
    if (accessToken) {
      dispatch(getHistories({ column: 'id' }));
    }
  }, [accessToken]);

  useEffect(() => {
    if (histories.data.error_code === 0) {
      const dataTemp = histories.data.results;
      const dataHistories = dataTemp.data || [];

      setRows(dataHistories);
      setPageSize(dataTemp?.meta?.pagination?.per_page);
      setPage(dataTemp?.meta?.pagination?.current_page - 1);
      setRowCount(dataTemp?.meta?.pagination?.total);
    }
  }, [histories]);

  const TableGridStyle = useMemo(
    () => ({ width: '100%', height: 'calc(100vh - 200px)', minHeight: 500 }),
    []
  );

  //handle page change
  const handlePageChange = (newPage) => {
    setPage(newPage);
    setRows([]);
    dispatch(
      getHistories({
        column: 'id',
        page: newPage + 1,
        per_page: pageSize,
      })
    );
  };
  //handle page size change
  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    dispatch(
      getHistories({
        column: 'id',
        page: page,
        per_page: newPageSize,
      })
    );
  };

  const formatPrice = (value) => {
    if (!value || isNaN(value)) {
      return '';
    };

    return parseInt(value).toFixed(0);
  };

  const columns = [
    {
      headerName: 'ID',
      field: 'id',
      width: 60,
    },
    {
      headerName: 'Name',
      field: 'name',
      valueGetter: (params) => params.row.user?.name,
      width: 200,
    },
    // {
    //   headerName: 'UserName',
    //   field: 'username',
    //   valueGetter: (params) => params.row.user?.username,
    //   width: 200,
    // },
    {
      headerName: 'Phone',
      field: 'phone',
      valueGetter: (params) => params.row.user?.phone,
      width: 150,
    },
    {
      headerName: 'Email',
      field: 'email',
      valueGetter: (params) => params.row.user?.email,
      width: 250,
    },
    {
      headerName: 'Address',
      field: 'address',
      valueGetter: (params) => params.row.user?.address,
      width: 300,
    },
    {
      headerName: 'Payment Method',
      field: 'payment_method',
      width: 150,
    },
    {
      headerName: 'Status',
      field: 'status',
      width: 150,
    },
    {
      headerName: 'Amount',
      field: 'amount',
      width: 150,
      valueFormatter: (params) => formatPrice(params.value),
    },
    {
      headerName: 'Transaction ID',
      field: 'transaction_id',
      width: 300,
    },
    {
      headerName: 'Product Type',
      field: 'product_type',
      valueGetter: (params) => params.row.product_subscription?.type,
      width: 150,
    },
    {
      headerName: 'Product Name',
      field: 'product_name',
      valueGetter: (params) => params.row.product_subscription?.name,
      width: 150,
    },
    {
      headerName: 'Product Price',
      field: 'product_price',
      valueGetter: (params) => params.row.product_subscription?.price,
      valueFormatter: (params) => formatPrice(params.value),
      width: 150,
    },
    {
      headerName: 'Create At',
      field: 'created_at',
      width: 200,
      valueFormatter: params => formatDate(params?.value, 'HH:mm DD/MM/YYYY'),
    },
  ];

  return (
    <>
      <div className="pt-3">
        <MuiTable
          columns={columns}
          rows={rows}
          rowID="id"
          rowsPerPageOptions={[5, 10, 15, 20, 50, 100]}
          pagination
          page={page}
          pageSize={pageSize}
          rowCount={rowCount}
          loading={histories.isLoading}
          gridStyle={TableGridStyle}
          setSelectedID={setSelectedID}
          setSelectedRows={setSelectedRows}
          onPageSizeChange={handlePageSizeChange}
          onPageChange={handlePageChange}
          labelRowsPerPage={'Chicken'}
        />
      </div>
    </>
  );
};

export default Histories;
