import { Select } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { getMember } from '../../../../store/user/member/memberActions';
import { setChildrenType } from '../../../../store/user/searchUsers/searchUsersActions';
const { Option } = Select;

const FilterChildren = ({ setTypeSelected, paramsQuery, setParamsQuery, setPage, setLoading }) => {
  const dispatch = useDispatch();

  const handleChangeFilter = (value) => {
    setPage(1);
    setLoading(true);
    setTypeSelected(value);
    dispatch(setChildrenType(value));

    if (value === 0) {
      dispatch(getMember({ ...paramsQuery, page: 1, childrenType: null }));
      setParamsQuery({ ...paramsQuery, childrenType: null });
      return;
    }

    dispatch(getMember({ ...paramsQuery, page: 1, childrenType: value }));
    setParamsQuery({ ...paramsQuery, childrenType: value });
  };

  return (
    <div>
      <label className="fw-bold me-1">Type</label>
      <Select
        placeholder={'Select value'}
        style={{ width: 250 }}
        defaultValue={1}
        onChange={handleChangeFilter}
        className="filterAgGrid"
      >
        {/* <Option value={0}>All</Option> */}
        <Option value={1}>Children</Option>
        <Option value={2}>People at risk of stroke</Option>
      </Select>
    </div>
  );
};

export default FilterChildren;
