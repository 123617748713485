import types from './examinationHistoryActionTypes';

export const getExaminationHistories = (values) => ({
  type: types.GET_EXAMINATION_HISTORIES,
  values,
});

export const setExaminationHistories = (values) => ({
  type: types.SET_EXAMINATION_HISTORIES,
  values,
});

export const getExaminationHistoryDetail = (values) => ({
  type: types.GET_EXAMINATION_HISTORY_DETAIL,
  values,
});

export const setExaminationHistoryDetail = (values) => ({
  type: types.SET_EXAMINATION_HISTORY_DETAIL,
  values,
});

export const createExaminationHistoryDetail = (values) => ({
  type: types.CREATE_EXAMINATION_HISTORY,
  values,
});

export const updateExaminationHistoryDetail = (values) => ({
  type: types.UPDATE_EXAMINATION_HISTORY,
  values,
});

export const deleteExaminationHistoryDetail = (values) => ({
  type: types.DELETE_EXAMINATION_HISTORY,
  values,
});
