import { all, call, put, takeLatest } from 'redux-saga/effects';
import apiInstance from '../../../../gateway/Instance';
import types from '../searchUsersActionTypes';
import { showSearchUsers } from '../searchUsersActions';

const searchUsersAPI = async ({ search, params }) => {
  const response = apiInstance.get(`/search-user/${encodeURIComponent(search)}`, { params });
  return response;
};

export function* searchUsersCredentials({ payload }) {
  try {
    yield put(showSearchUsers({ isLoading: true, data: [], isError: false, isClearSearch: false }));
    const result = yield call(searchUsersAPI, payload);
    yield put(
      showSearchUsers({ isLoading: false, data: result.data, isError: false, isClearSearch: false })
    );
  } catch (error) {
    console.log('🚀 ~ file: index.js:17 ~ function*getUserRoleCredentials ~ error:', error);
    yield put(showSearchUsers({ isLoading: false, data: [], isError: true, isClearSearch: false }));
  }
}

export function* onSearchUsersStart() {
  yield takeLatest(types.SEARCH_USERS, searchUsersCredentials);
}

export function* searchUsersSagas() {
  yield all([call(onSearchUsersStart)]);
}
