/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//import { registerStart } from "../../store/register/registerActions";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import CloseIcon from '@mui/icons-material/Close';
import { DialogContent, DialogTitle, IconButton, TextField } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { Button, Form, Space, message, notification } from 'antd';
import FormSelect from '../../../../components/ColFormItem/FormSelect';
import RepeatLocation from '../../../../components/DynamicFormItem/RepeatLocation';
import { getProvinces } from '../../../../store/address/addressActions';
import {
  addMemberStart,
  addMemberSuccess,
} from '../../../../store/user/addMember/addMemberActions';
import { getMember } from '../../../../store/user/member/memberActions';
import LoadingWrapper from '../../../../components/LoadingCustom/LoadingWrapper';

const AddNewEmployee = ({ pageSize, setOpenAdd, setLoadingList, handleClose }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const roleID = 2;
  const optionsType = [
    { value: 1, label: 'Children' },
    { value: 2, label: 'People at risk of stroke' },
  ];
  const initialLocation = [
    {
      province_id: null,
      district_id: [],
    },
  ];

  const [userVerified, setUserVerified] = useState(false);
  const [optionsProvinces, setOptionsProvinces] = useState([]);
  const [loading, setLoading] = useState(false);

  const addMemberStatus = useSelector((state) => state.addMemberReducer.items);
  const provinces = useSelector((state) => state.addressReducer.provinces);

  useEffect(() => {
    dispatch(getProvinces());
  }, []);

  useEffect(() => {
    if (addMemberStatus.status === true) {
      setLoadingList(true);
      setLoading(false);
      setOpenAdd(false);

      dispatch(getMember({ roleID: roleID, page: 1, per_page: pageSize }));
      resetStatusAction();
    } else if (!addMemberStatus.status && addMemberStatus.isError) {
      setLoading(false);
      resetStatusAction();
    }
  }, [addMemberStatus]);

  useEffect(() => {
    if (provinces.data.length > 0) {
      const optionsProvincesTemp = provinces.data?.map((item) => ({
        label: item.name,
        value: item.id,
      }));

      setOptionsProvinces(optionsProvincesTemp);
    } else {
      setOptionsProvinces([]);
    }
  }, [provinces]);

  const resetStatusAction = () => {
    return dispatch(addMemberSuccess({ isLoading: false, status: false, isError: false }));
  };

  const handleChangeChecked = (event) => {
    setUserVerified(event.target.checked);
  };

  const handleSubmit = (values) => {

    if (!values.name) {
      alert('Please enter field FullName');
      return;
    } else if (!values.password) {
      alert('Please enter field Password');
      return;
    } else if (!values.phone) {
      alert('Please enter field Phone');
      return;
    } else if (!values.email) {
      alert('Please enter field Email');
      return;
    } else if (values?.location?.length <= 0) {
      alert('Please enter field Locations');
      return;
    } else if (!values.type) {
      alert('Please select field Type');
      return;
    }

    setLoading(true);

    const paramsSubmit = {
      ...values,
      role: roleID,
      user_verified: userVerified ? '1' : '0',
    };

    // Handle submit success
    dispatch(addMemberStart(paramsSubmit));
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
    notification.error({
      message: 'Error',
      description: 'Add new employee failed!',
    });
  };

  const reRenderOptionsProvinces = () => {
    const location = form.getFieldValue('location') || [];
    const provincesTemp = location?.map((item) => item?.province_id) || [];
    const selectedProvincesTemp = [...new Set(provincesTemp)];

    const resultTemp = optionsProvinces.map((item) => {
      if (selectedProvincesTemp.includes(item.value)) {
        item.disabled = true;
      } else {
        item.disabled = false;
      }

      return item;
    });

    setOptionsProvinces(resultTemp);
  };

  return (
    <>
      <DialogTitle className="fw-bold">
        <span>Add New Employee</span>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <LoadingWrapper loading={loading}>
          <div className="addNewEmployee_main fs-15 pt-2">
            <Form
              form={form}
              name="createEmployee"
              onFinish={handleSubmit}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              initialValues={{location: initialLocation}}
            >
              <div className="row">
                <div className="col-12 col-lg-6">
                  <Form.Item name="name">
                    <TextField
                      name="name"
                      fullWidth
                      required
                      id="outlined-fullname"
                      label="Fullname"
                    />
                  </Form.Item>
                </div>
                <div className="col-12 col-lg-6">
                  <Form.Item name="password">
                    <TextField
                      name="password"
                      type={'password'}
                      fullWidth
                      required
                      id="outlined-password"
                      label="Password"
                      autoComplete="off"
                    />
                  </Form.Item>
                </div>
                <div className="col-12 col-lg-6">
                  <Form.Item name="phone">
                    <TextField name="phone" fullWidth required id="phone" label="Phone" />
                  </Form.Item>
                </div>
                <div className="col-12 col-lg-6">
                  <Form.Item name="email">
                    <TextField
                      name="email"
                      required
                      fullWidth
                      id="outlined-email"
                      label="Email"
                      autoComplete="off"
                    />
                  </Form.Item>
                </div>
                <div className="col-12 col-lg-6">
                  <Form.Item name="address">
                    <TextField name="address" fullWidth id="outlined-address" label="Address" />
                  </Form.Item>
                </div>

                <div className="col-12 col-lg-6 mt-1">
                  <Form.Item name="user_verified">
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox onChange={handleChangeChecked} name="user_verified" />}
                        label="User Verified"
                      />
                    </FormGroup>
                  </Form.Item>
                </div>

                <FormSelect
                  required
                  label="Type"
                  name="type"
                  options={optionsType}
                  classCol="col-12 col-lg-6"
                  classNameSelect="ant-select-custom"
                />

                <div className="col-12 col-lg-6" />

                <div className="col-12">
                  <Form.List name="location">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }) => (
                          <Space key={key} className="w-100 d-block" align="baseline">
                            <div className="row">
                              <RepeatLocation
                                form={form}
                                restField={restField}
                                name={name}
                                optionsProvinces={optionsProvinces}
                              />
                              <div className="col-12 col-md-1 p-0 mt-0 mt-md-2 mb-2 mb-md-0">
                                <div className="h-75 d-flex align-items-center justify-content-center">
                                  <MinusCircleOutlined
                                    onClick={() => {
                                      remove(name);
                                      reRenderOptionsProvinces();
                                      return;
                                    }}
                                    className="text-red"
                                  />
                                </div>
                              </div>
                            </div>
                          </Space>
                        ))}
                        <Form.Item>
                          <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
                            Add Locations
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                </div>
              </div>
              <div className="mw-930 mx-auto text-end">
                <Form.Item>
                  <Button type="primary" className="fw-bold rounded" size="large" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
        </LoadingWrapper>
      </DialogContent>
    </>
  );
};
export default AddNewEmployee;
