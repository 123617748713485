import { all, call, put, takeLatest } from 'redux-saga/effects';
import apiInstance from '../../../../gateway/Instance';

import { showMemberDetail } from '../memberDetailActions';

import types from '../memberDetailActionTypes';

// Get Member Detail API
const getMemberDetailAPI = async (IDMember) => {
  if (IDMember) {
    const response = apiInstance.get(`/user/${IDMember}`);
    return response;
  }
};

// Get Member Detail
export function* getMemberDetailCredentials({ payload }) {
  try {
    yield put(showMemberDetail({ isLoading: true, data: [], isError: false }));
    const result = yield call(getMemberDetailAPI, payload);
    yield put(showMemberDetail({ isLoading: false, data: result, isError: false }));
  } catch (error) {
    yield put(showMemberDetail({ isLoading: false, data: [], isError: true }));
  }
}

export function* onMemberDetailStart() {
  yield takeLatest(types.GET_MEMBER_DETAIL, getMemberDetailCredentials);
}

export function* memberDetailSagas() {
  yield all([call(onMemberDetailStart)]);
}
