import React from "react";
import MainMenu from "./MainMenu";
import "./sidebar.scss";

const SideBar = ({ onClose }) => {
  return (
    <>
      <div className="sideBar-main h-100 pt-1 pb-2">
        <div className="sideBar-main_list position-relative">
          <MainMenu onClose={onClose} />
        </div>
      </div>
    </>
  );
};
export default SideBar;
