import BusinessIcon from '@mui/icons-material/Business';
import DashboardIcon from '@mui/icons-material/Dashboard';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import EscalatorWarningIcon from '@mui/icons-material/EscalatorWarning';
import HistoryIcon from '@mui/icons-material/History';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import SecurityIcon from '@mui/icons-material/Security';
import InventoryIcon from '@mui/icons-material/Inventory';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { objectIsEmpty } from '../../../utils/supportFunction';
import ActiveLink from '../ActiveLink';
import '../sidebar.scss';
import IconDoctor from '../../../images/doctor.png';
import IconUserManagement from '../../../images/userManagement.png';
import IconHistoriesExamine from '../../../images/historiesExamine.png';

const MainMenu = ({ onClose }) => {
  const [isActive, setActive] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  const profileUserLogin = useSelector((state) => state.profileReducer.items);

  useEffect(() => {
    if (!profileUserLogin.isLoading && !objectIsEmpty(profileUserLogin.data?.data)) {
      return profileUserLogin.data?.data?.user_info?.is_super_admin
        ? setIsSuperAdmin(true)
        : setIsSuperAdmin(false);
    }
  }, [profileUserLogin]);

  const handleToggleClick = () => {
    setActive(true);
  };
  const handleCloseSubMenu = () => {
    setActive(false);
  };

  const renderImage = ({ icon, size = 23, alt }) => {
    return <img src={icon} width={size} alt={alt || 'Image'} />;
  };

  return (
    <>
      <ul className="h-100 ls-1 scroll_style">
        <li onClick={handleCloseSubMenu}>
          <ActiveLink
            icon={<DashboardIcon />}
            name={'Dashboard'}
            to="/"
            onClose={onClose}
          ></ActiveLink>
        </li>
        {isSuperAdmin && (
          <li onClick={handleCloseSubMenu}>
            <ActiveLink
              icon={renderImage({ icon: IconUserManagement, alt: 'Quản lý nhân viên GGI' })}
              name={'Quản lý nhân viên GGI'}
              to="/employee-management"
              onClose={onClose}
            ></ActiveLink>
          </li>
        )}
        <li onClick={handleCloseSubMenu}>
          <ActiveLink
            icon={<PersonOutlinedIcon />}
            name={'Nhân viên GGI'}
            to="/employee"
            onClose={onClose}
          ></ActiveLink>
        </li>
        <li onClick={handleCloseSubMenu}>
          <ActiveLink
            icon={renderImage({ icon: IconDoctor, alt: 'Doctor' })}
            name={'Bác sĩ'}
            to="/doctor"
            onClose={onClose}
          />
        </li>
        <li onClick={handleCloseSubMenu}>
          <ActiveLink
            icon={<EscalatorWarningIcon />}
            name={'Danh sách khách hàng'}
            to="/supervisor"
            onClose={onClose}
          ></ActiveLink>
        </li>
        <li onClick={handleCloseSubMenu}>
          <ActiveLink
            icon={<EmojiPeopleIcon />}
            name={'Người cần theo dõi'}
            to="/children"
            onClose={onClose}
          ></ActiveLink>
        </li>
        <li onClick={handleCloseSubMenu}>
          <ActiveLink
            icon={<SecurityIcon />}
            name={'Nhân viên Vệ sĩ'}
            to="/bodyguard"
            onClose={onClose}
          ></ActiveLink>
        </li>
        <li onClick={handleCloseSubMenu}>
          <ActiveLink
            icon={<HistoryIcon />}
            name={'Lịch sử đặt hàng'}
            to="/histories"
            onClose={onClose}
          />
        </li>
        <li onClick={handleCloseSubMenu}>
          <ActiveLink
            icon={<BusinessIcon />}
            name={'Bệnh viện'}
            to="/hospital"
            onClose={onClose}
          ></ActiveLink>
        </li>
        {/* <li onClick={handleCloseSubMenu}>
          <ActiveLink
            icon={<RouteIcon />}
            name={'Bác sĩ'}
            to="/doctor"
            onClose={onClose}
          ></ActiveLink>
        </li>
        <li onClick={handleCloseSubMenu}>
          <ActiveLink
            icon={<FavoriteIcon />}
            name={'Hồ sơ sức khoẻ'}
            to="/health-records"
            onClose={onClose}
          ></ActiveLink>
        </li> */}
        <li onClick={handleCloseSubMenu}>
          <ActiveLink
            icon={<LocationOnIcon />}
            name={'Quản lý khu vực theo dõi'}
            to="/area-tracking"
            onClose={onClose}
          ></ActiveLink>
        </li>
        {/* <li onClick={handleCloseSubMenu}>
          <ActiveLink
            icon={<WatchOutlinedIcon />}
            name={'Quản lý thiết bị tracking'}
            to="/devices-tracking"
            onClose={onClose}
          ></ActiveLink>
        </li>
        <li onClick={handleCloseSubMenu}>
          <ActiveLink
            icon={<SettingsIcon />}
            name={'Cài đặt'}
            to="/setting"
            onClose={onClose}
          ></ActiveLink>
        </li>
        <li onClick={handleCloseSubMenu}>
          <ActiveLink
            icon={<MiscellaneousServicesIcon />}
            name={'Gói cài đặt'}
            to="/package-setting"
            onClose={onClose}
          ></ActiveLink>
        </li>
        <li onClick={handleCloseSubMenu}>
          <ActiveLink
            icon={<NotificationsNoneIcon />}
            name={'Thông báo'}
            to="/notification"
            onClose={onClose}
          ></ActiveLink>
        </li> */}

        <li onClick={handleCloseSubMenu}>
          <ActiveLink
            icon={renderImage({ icon: IconHistoriesExamine, alt: 'Lịch sử khám' })}
            name={'Lịch sử khám'}
            to="/examination-history"
            onClose={onClose}
          />
        </li>
        <li onClick={handleCloseSubMenu}>
          <ActiveLink
            icon={<InventoryIcon />}
            name={'Quản lý gói'}
            to="/package"
            onClose={onClose}
          />
        </li>
      </ul>
    </>
  );
};
export default MainMenu;
