import types from './editHospitalActionTypes';

export const getHospitalEdit = (selectedID) => ({
  type: types.GET_HOSPITAL_EDIT,
  payload: selectedID,
});

export const updateHospitalEdit = (selectedID) => ({
  type: types.UPDATE_HOSPITAL_EDIT,
  payload: selectedID,
});
