import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MuiTable from '../../../components/MuiTable';
import {
  deleteUsers,
  updateItemDelete,
} from '../../../store/user/deleteMember/deleteMemberActions';
import { getMember } from '../../../store/user/member/memberActions';
import { getMemberDetail } from '../../../store/user/memberDetail/memberDetailActions';
import {
  setSelectedUser,
  showSearchUsers,
} from '../../../store/user/searchUsers/searchUsersActions';
import { formatDate } from '../../../utils/supportFunction';
import '../dashboard.scss';
import AddNewSupervisor from './AddNewSupervisor';
import EditSupervisor from './EditSupervisor';
import EditSupervisorTime from './EditSupervisorTime';
import { message } from 'antd';
import LoadingWrapper from '../../../components/LoadingCustom/LoadingWrapper';

const Supervisor = () => {
  const dispatch = useDispatch();

  const roleID = 4;
  let accessToken = localStorage.getItem('access_token');

  const [rows, setRows] = useState([]);
  const [selectedID, setSelectedID] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [name, setName] = useState(null);
  const [pageSize, setPageSize] = useState(15);
  const [rowCount, setRowCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [openEditTime, setOpenEditTime] = useState(false);
  const [btnTimeDisable, setBtnTimeDisable] = useState(true);

  useEffect(() => {
    if (accessToken) {
      dispatch(getMember({ roleID: roleID, page: 1, per_page: pageSize }));
    }
  }, [accessToken]);

  const memData = useSelector((state) => state.memberReducer.items.data);
  const deleteStatus = useSelector((state) => state.deleteReducer.items);
  const selectedUser = useSelector((state) => state.searchUsersReducer.selectedUser);
  const resultSearchUsers = useSelector((state) => state.searchUsersReducer.items);

  useEffect(() => {
    if (memData.error_code === 0 && memData.results.filter_by_role === roleID) {
      const paginationTemp = memData.results.meta.pagination;

      setRows(memData.results.data);
      setPageSize(paginationTemp.per_page);
      setPage(paginationTemp.current_page - 1);
      setRowCount(paginationTemp.total);
      setLoading(false);
    }
  }, [memData]);

  useEffect(() => {
    if (!resultSearchUsers.isLoading && resultSearchUsers.data?.length > 0) {
      setRows(resultSearchUsers.data);
      setRowCount(resultSearchUsers.data?.length);
      setPage(0);
    } else if (resultSearchUsers.isClearSearch && !resultSearchUsers.data?.length) {
      setRows(memData?.results?.data);
      setPageSize(memData.results.meta.pagination.per_page);
      setPage(memData.results.meta.pagination.current_page - 1);
      setRowCount(memData.results.meta.pagination.total);
      dispatch(
        showSearchUsers({ isLoading: false, data: [], isError: false, isClearSearch: false })
      );
    }
  }, [resultSearchUsers]);

  useEffect(() => {
    if (deleteStatus.status) {
      message.success('Deleted user success!');
      dispatch(getMember({ roleID: roleID, page: page, per_page: pageSize }));
      resetStatusAction();
    } else if (!deleteStatus.status && deleteStatus.isError) {
      setLoading(false);
      resetStatusAction();
    }
  }, [deleteStatus]);

  useEffect(() => {
    selectedID.length > 0 && selectedID.length < 2
      ? setBtnTimeDisable(false)
      : setBtnTimeDisable(true);
  }, [selectedID]);

  useEffect(() => {
    if (selectedUser.status && selectedUser.role === roleID && selectedUser.userId) {
      setOpenEdit(true);
      dispatch(getMemberDetail(selectedUser.userId));
      dispatch(setSelectedUser({ status: false, role: null, userId: null }));
    }
  }, [selectedUser]);

  const TableGridStyle = useMemo(
    () => ({ width: '100%', height: 'calc(100vh - 250px)', minHeight: 500 }),
    []
  );

  const resetStatusAction = () => {
    return dispatch(updateItemDelete({ isLoading: false, status: false, isError: false }));
  };

  const formatUserVerify = (params) => {
    return params.value ? 'Yes' : 'No';
  };

  const columns = [
    {
      headerName: 'ID',
      field: 'id',
      width: 60,
    },
    {
      headerName: 'Name',
      field: 'name',
      width: 120,
    },
    // {
    //   headerName: 'UserName',
    //   field: 'username',
    //   width: 120,
    // },
    {
      headerName: 'Phone',
      field: 'phone',
      width: 150,
    },
    {
      headerName: 'Email',
      field: 'email',
      width: 250,
    },
    {
      headerName: 'User Verify',
      field: 'user_verified',
      width: 150,
      valueFormatter: formatUserVerify,
    },
    {
      headerName: 'Address',
      field: 'address',
      width: 350,
    },
    {
      headerName: 'Service Expire At',
      field: 'service_expire_at',
      width: 200,
    },
    {
      headerName: 'Create Date',
      field: 'created_at',
      width: 150,
      valueFormatter: (params) => formatDate(params.value),
    },
  ];

  const handleDeleteRow = () => {
    setOpen(true);
    setName(selectedRows[0].name);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    setOpen(false);
    setLoading(true);
    dispatch(deleteUsers(selectedID));
  };

  //add new
  const handleAddNewClose = () => {
    setOpenAdd(false);
  };
  const handleAddNewRow = () => {
    setOpenAdd(true);
  };

  const handleEditTime = () => {
    setOpenEditTime(true);
    setName(selectedRows[0].name);
    dispatch(getMemberDetail(selectedID));
  };

  //Edit Children
  const handleEditClose = () => {
    setOpenEdit(false);
  };
  const handleEditRow = () => {
    setOpenEdit(true);
    setName(selectedRows[0].name);
    dispatch(getMemberDetail(selectedID));
  };

  //handle page change
  const handlePageChange = (newPage) => {
    setPage(newPage);
    setLoading(true);
    setRows([]);
    dispatch(
      getMember({
        roleID: roleID,
        page: newPage + 1,
        per_page: pageSize,
      })
    );
  };

  //handle page size change
  const handlePageSizeChange = (newPageSize) => {
    setPage(0);
    setPageSize(newPageSize);
    setLoading(true);
    dispatch(
      getMember({
        roleID: roleID,
        page: 1,
        per_page: newPageSize,
      })
    );
  };

  const buttonUpdateTimes = () => {
    return (
      <Button
        className="me-1"
        variant="contained"
        disabled={btnTimeDisable}
        color="secondary"
        startIcon={<EditIcon />}
        onClick={handleEditTime}
      >
        Edit Service Expire
      </Button>
    );
  };

  return (
    <>
      <div className="pt-3">
        <LoadingWrapper loading={loading}>
          <MuiTable
            setSelectedID={setSelectedID}
            setSelectedRows={setSelectedRows}
            columns={columns}
            rows={rows}
            gridStyle={TableGridStyle}
            checkboxSelection
            singleSelected
            rowID="id"
            showAddButton
            showEditButton
            showDeleteButton
            handleDeleteRow={handleDeleteRow}
            handleAddNewRow={handleAddNewRow}
            handleEditRow={handleEditRow}
            rowsPerPageOptions={[5, 10, 15, 20, 50, 100]}
            pagination
            page={page}
            pageSize={pageSize}
            rowCount={rowCount}
            loading={loading}
            onPageSizeChange={handlePageSizeChange}
            onPageChange={handlePageChange}
            labelRowsPerPage={'Chicken'}
            moreButtonRight={buttonUpdateTimes()}
          />
        </LoadingWrapper>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle id="alert-dialog-title">Are you sure delete {name}</DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>No</Button>
            <Button onClick={handleConfirm} autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openAdd} maxWidth={'md'} fullWidth={true} onClose={handleAddNewClose}>
          <AddNewSupervisor
            pageSize={pageSize}
            setOpenAdd={setOpenAdd}
            setLoadingList={setLoading}
            handleClose={handleAddNewClose}
          />
        </Dialog>
        <Dialog open={openEdit} maxWidth={'md'} fullWidth={true} onClose={handleEditClose}>
          <EditSupervisor
            selectedID={selectedID}
            name={name}
            page={page}
            pageSize={pageSize}
            setOpenEdit={setOpenEdit}
            setLoadingList={setLoading}
            handleClose={handleEditClose}
          />
        </Dialog>
        <EditSupervisorTime
          name={name}
          page={page}
          pageSize={pageSize}
          openModal={openEditTime}
          setOpenModal={setOpenEditTime}
          setLoadingList={setLoading}
        />
      </div>
    </>
  );
};
export default Supervisor;
