import { all, call, put, takeEvery } from 'redux-saga/effects';
import types from './packageActionTypes';
import { setPackageDetail, setPackageTypes, setPackages } from './packageActions';
import { INITIAL_STATE_REDUCER } from '../../constants/Constants';
import apiInstance from '../../gateway/InstanceMain';

// ============ API ============

const getPackagesAPI = async (params) => {
  const response = await apiInstance.get('/product-subscription', { params });
  return response;
};

const getPackageDetailAPI = async (id) => {
  const response = await apiInstance.get(`/product-subscription/${id}`);
  return response;
};

const getPackageTypesAPI = async (params) => {
  const response = await apiInstance.get('/product-subscription/types', { params });
  return response;
};

export const createPackageAPI = async (params) => {
  const response = await apiInstance.post('/product-subscription', params);
  return response;
};

export const updatePackageAPI = async ({ id, params }) => {
  const response = await apiInstance.put(`/product-subscription/${id}`, params);
  return response;
};

export const deletePackageAPI = async (id) => {
  const response = await apiInstance.delete(`/product-subscription/${id}`);
  return response;
};

// ============ Call API ============

function* getPackagesCredentials({ values }) {
  try {
    yield put(setPackages(INITIAL_STATE_REDUCER.OBJECT_LOADING));
    const result = yield call(getPackagesAPI, values);
    yield put(setPackages({ ...INITIAL_STATE_REDUCER.OBJECT, data: result.results }));
  } catch (error) {
    console.log('getPackagesCredentials Error: ', error);
    yield put(setPackages(INITIAL_STATE_REDUCER.OBJECT_ERROR));
  }
}

function* getPackageDetailCredentials({ values }) {
  try {
    yield put(setPackageDetail(INITIAL_STATE_REDUCER.OBJECT_LOADING));
    const result = yield call(getPackageDetailAPI, values);
    yield put(setPackageDetail({ ...INITIAL_STATE_REDUCER.OBJECT, data: result.data }));
  } catch (error) {
    console.log('getPackageDetailCredentials Error: ', error);
    yield put(setPackageDetail(INITIAL_STATE_REDUCER.OBJECT_ERROR));
  }
}

function* getPackageTypesCredentials({ values }) {
  try {
    yield put(setPackageTypes(INITIAL_STATE_REDUCER.ARRAY_LOADING));
    const result = yield call(getPackageTypesAPI, values);
    yield put(setPackageTypes({ ...INITIAL_STATE_REDUCER.ARRAY, data: result.data }));
  } catch (error) {
    console.log('getPackageTypesCredentials Error: ', error);
    yield put(setPackageTypes(INITIAL_STATE_REDUCER.ARRAY_ERROR));
  }
}

function* onPackageStart() {
  yield takeEvery(types.GET_PACKAGES, getPackagesCredentials);
  yield takeEvery(types.GET_PACKAGE_DETAIL, getPackageDetailCredentials);
  yield takeEvery(types.GET_PACKAGE_TYPES, getPackageTypesCredentials);
}

export function* packageSagas() {
  yield all([call(onPackageStart)]);
}
