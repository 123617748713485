const examinationHistoryActionTypes = {
  GET_EXAMINATION_HISTORIES: 'GET_EXAMINATION_HISTORIES',
  SET_EXAMINATION_HISTORIES: 'SET_EXAMINATION_HISTORIES',
  GET_EXAMINATION_HISTORY_DETAIL: 'GET_EXAMINATION_HISTORY_DETAIL',
  SET_EXAMINATION_HISTORY_DETAIL: 'SET_EXAMINATION_HISTORY_DETAIL',
  CREATE_EXAMINATION_HISTORY: 'CREATE_EXAMINATION_HISTORY',
  UPDATE_EXAMINATION_HISTORY: 'UPDATE_EXAMINATION_HISTORY',
  DELETE_EXAMINATION_HISTORY: 'DELETE_EXAMINATION_HISTORY',
  SET_ACTIONS_EXAMINATION_HISTORY: 'SET_ACTIONS_EXAMINATION_HISTORY',
};

export default examinationHistoryActionTypes;
