import { all, call, put, takeLatest } from 'redux-saga/effects';
import apiInstance from '../../../../gateway/Instance';

import { showHospitalDetail } from '../hospitalDetailActions';

import types from '../hospitalDetailActionTypes';

// Get Hospital Detail API
const getHospitalDetailAPI = async (IDHospital) => {
  if (IDHospital) {
    const response = apiInstance.get(`/hospital/${IDHospital}`);
    return response;
  }
};

// Get Hospital Detail
export function* getHospitalDetailCredentials({ payload }) {
  try {
    yield put(showHospitalDetail({ isLoading: true, data: [], isError: false }));
    const result = yield call(getHospitalDetailAPI, payload);
    yield put(showHospitalDetail({ isLoading: false, data: result, isError: false }));
  } catch (error) {
    yield put(showHospitalDetail({ isLoading: false, data: [], isError: true }));
  }
}

export function* onHospitalDetailStart() {
  yield takeLatest(types.GET_HOSPITAL_DETAIL, getHospitalDetailCredentials);
}

export function* hospitalDetailSagas() {
  yield all([call(onHospitalDetailStart)]);
}
