import types from './changePasswordActionTypes';

export const getChangePassword = (values) => ({
  type: types.GET_CHANGE_PASSWORD,
  payload: values,
});

export const showChangePassword = (values) => ({
  type: types.SHOW_CHANGE_PASSWORD,
  payload: values,
});
