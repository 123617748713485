import { all, call, put, takeLatest } from 'redux-saga/effects';
import instanceMain from '../../../../gateway/InstanceMain';
import { message } from 'antd';

import { showChangePassword } from '../changePasswordActions';

import types from '../changePasswordActionTypes';

// Get Member Detail API
const getChangePasswordAPI = async (values) => {
  const response = instanceMain
    .post(`/change-password`, values)
    .then((res) => {
      message.success('Change Password success');
    })
    .catch((error) => console.log(error));
  return response;
};

// Get Member Detail
export function* getChangePasswordCredentials({ payload }) {
  try {
    yield put(showChangePassword({ isLoading: true, status: false, isError: false }));
    yield call(getChangePasswordAPI, payload);
    yield put(showChangePassword({ isLoading: false, status: true, isError: false }));
  } catch (error) {
    yield put(showChangePassword({ isLoading: false, data: false, isError: true }));
  }
}

export function* onChangePasswordStart() {
  yield takeLatest(types.GET_CHANGE_PASSWORD, getChangePasswordCredentials);
}

export function* changePasswordSagas() {
  yield all([call(onChangePasswordStart)]);
}
