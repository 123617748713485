import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//import { registerStart } from "../../store/register/registerActions";
import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import { message } from 'antd';
import { getItemEdit, updateItemEdit } from '../../../../store/user/editMember/editMemberActions';
import { getMember } from '../../../../store/user/member/memberActions';
import LoadingWrapper from '../../../../components/LoadingCustom/LoadingWrapper';

const EditChildren = ({ name, paramsQuery, handleClose, setOpenEdit, setLoadingList }) => {
  const dispatch = useDispatch();

  const [memberDataDetail, setMemberDataDetail] = useState({});
  const [values, setValues] = useState({});
  const [emailDtl, setEmailDtl] = useState();
  const [phoneDtl, setPhoneDtl] = useState();
  const [checkedVal, setCheckedVal] = useState(false);
  const [loading, setLoading] = useState(true);

  const memDetailData = useSelector((state) => state.memberDetailReducer.items.data);
  const editStatus = useSelector((state) => state.editMemberReducer.items);

  useEffect(() => {
    if (memDetailData.error_code === 0) {
      setMemberDataDetail(memDetailData.data);
      const infoData = memDetailData.data;
      setEmailDtl(infoData.email);
      setPhoneDtl(infoData.phone);
      infoData?.user_verified ? setCheckedVal(true) : setCheckedVal(false);

      setLoading(false);
    } else if (memDetailData.error_code === 1) {
      setLoading(false);
      handleClose();
    }
  }, [memDetailData]);

  // set Value State
  useEffect(() => {
    if (memberDataDetail) {
      setValues({
        name: memberDataDetail.name,
        address: memberDataDetail.address,
        type: memberDataDetail.user_info?.type,
      });
    }
  }, [memberDataDetail]);

  useEffect(() => {
    if (editStatus.status === true) {
      setLoadingList(true);
      setLoading(false);
      setOpenEdit(false);

      dispatch(getMember(paramsQuery));
      dispatch(updateItemEdit({ isLoading: false, status: false, isError: false }));

      message.success('Updated user success!');
    } else if (!editStatus.status && editStatus.isError) {
      setLoading(false);
      message.error('Update user failed!');
    }
  }, [editStatus]);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeChecked = (event) => {
    setCheckedVal(event.target.checked);
    setValues({
      ...values,
      [event.target.name]: event.target.checked,
    });
  };

  const handleSubmit = () => {
    if (!values.name) {
      alert('Please enter field FullName');
      return;
    } else if (!values.type) {
      alert('Please select field Type');
      return;
    }

    setLoading(true);

    const valueEdit = {
      ...values,
      user_verified: values.user_verified ? 1 : 0,
    };

    // Handle submit success
    dispatch(
      getItemEdit({
        selectedID: memberDataDetail?.id,
        values: valueEdit,
      })
    );
  };

  return (
    <>
      <DialogTitle className="fw-bold">
        <span>Edit Children {name}</span>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <LoadingWrapper loading={loading}>
          <div className="addNewChildren_main fs-15 pt-2 pb-2">
            <form className="" autoComplete="off" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-12 col-lg-6 mb-2">
                  <TextField
                    name="name"
                    value={values.name}
                    fullWidth
                    required
                    id="outlined-fullname"
                    label="Fullname"
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className="col-12 col-lg-6 mb-2">
                  <TextField
                    name="password"
                    type={'password'}
                    fullWidth
                    id="outlined-password"
                    label="New Password"
                    onChange={handleChange}
                    autoComplete="new-password"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className="col-12 col-lg-6 mb-2">
                  <TextField
                    name="email"
                    value={emailDtl}
                    fullWidth
                    required
                    id="outlined-email"
                    label="Email"
                    disabled
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className="col-12 col-lg-6 mb-2">
                  <TextField
                    name="phone"
                    value={phoneDtl}
                    fullWidth
                    required
                    id="outlined-phone"
                    label="Phone"
                    disabled
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className="col-12 col-lg-6 mb-2">
                  <TextField
                    name="address"
                    value={values.address}
                    fullWidth
                    id="outlined-address"
                    label="Address"
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className="col-12 col-lg-6 mb-2">
                  <FormControl fullWidth>
                    <InputLabel id="type">Type *</InputLabel>
                    <Select
                      labelId="type"
                      id="outlined-types"
                      value={values.type || 1}
                      name="type"
                      required
                      label="Type"
                      onChange={handleChange}
                    >
                      <MenuItem value={1}>Children</MenuItem>
                      <MenuItem value={2}>People at risk of stroke</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="col-12 col-lg-6 mb-2">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!!checkedVal}
                          onChange={handleChangeChecked}
                          name="user_verified"
                        />
                      }
                      label="User Verified"
                    />
                  </FormGroup>
                </div>
              </div>
              <div className="mw-930 mx-auto text-end">
                <Button
                  color="primary"
                  className="fw-bold"
                  variant="contained"
                  size="large"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </LoadingWrapper>
      </DialogContent>
    </>
  );
};
export default EditChildren;
