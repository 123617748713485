import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MuiTable from '../../../components/MuiTable';
import { getHospitalDelete } from '../../../store/hospital/deleteHospital/deleteHospitalActions';
import { getHospitalDetail } from '../../../store/hospital/hospitalDetail/hospitalDetailActions';
import { getHospital } from '../../../store/hospital/hospitalList/hospitalActions';
import '../dashboard.scss';
import AddNewHospital from './AddNewHospital';
import EditHospital from './EditHospital';
import LoadingWrapper from '../../../components/LoadingCustom/LoadingWrapper';

const Hospital = () => {
  const dispatch = useDispatch();

  let accessToken = localStorage.getItem('access_token');

  const [rows, setRows] = useState([]);
  const [selectedID, setSelectedID] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [name, setName] = useState(null);
  const [pageSize, setPageSize] = useState(15);
  const [rowCount, setRowCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);

  useEffect(() => {
    if (accessToken) {
      dispatch(
        getHospital({
          page: 1,
          per_page: pageSize,
        })
      );
    }
  }, [accessToken]);

  const hospitalData = useSelector((state) => state.hospitalReducer.items.data);
  const deleteStatus = useSelector((state) => state.deleteHospitalReducer.items);
  const addHospitalStatus = useSelector((state) => state.addHospitalReducer.items);
  const editStatus = useSelector((state) => state.editHospitalReducer.items);

  useEffect(() => {
    if (hospitalData.error_code === 0) {
      setRows(hospitalData.results.data);
      setPageSize(hospitalData.results.meta.pagination.per_page);
      setPage(hospitalData.results.meta.pagination.current_page - 1);
      setRowCount(hospitalData.results.meta.pagination.total);
      setLoading(false);
    }
  }, [hospitalData]);

  useEffect(() => {
    if (deleteStatus.status === true) {
      dispatch(
        getHospital({
          page: 1,
          per_page: 5,
        })
      );
      if (hospitalData.error_code === 0) {
        setRows(hospitalData.results.data);
      }
    }
  }, [deleteStatus]);

  useEffect(() => {
    if (addHospitalStatus.status === true) {
      dispatch(
        getHospital({
          page: 1,
          per_page: 5,
        })
      );
      if (hospitalData.error_code === 0) {
        setRows(hospitalData.results.data);
      }
      setOpenAdd(false);
    }
  }, [addHospitalStatus]);

  useEffect(() => {
    if (editStatus.status === true) {
      dispatch(
        getHospital({
          page: 1,
          per_page: 5,
        })
      );
      if (hospitalData.error_code === 0) {
        setRows(hospitalData.results.data);
      }
      setOpenEdit(false);
    }
  }, [editStatus]);

  const TableGridStyle = useMemo(
    () => ({ width: '100%', height: 'calc(100vh - 250px)', minHeight: 500 }),
    []
  );

  const columns = [
    {
      headerName: 'ID',
      field: 'id',
      width: 100,
    },
    {
      headerName: 'Name',
      field: 'name',
      width: 220,
    },
    {
      headerName: 'Short Desc',
      field: 'short_desc',
      width: 450,
    },
    {
      headerName: 'Description',
      field: 'desc',
      width: 550,
    },
  ];

  const handleDeleteRow = () => {
    setOpen(true);
    setName(selectedRows[0].name);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    setOpen(false);
    dispatch(getHospitalDelete(selectedID));
  };

  //add new
  const handleAddNewClose = () => {
    setOpenAdd(false);
  };
  const handleAddNewRow = () => {
    setOpenAdd(true);
  };

  //Edit Hospital
  const handleEditClose = () => {
    setOpenEdit(false);
  };
  const handleEditRow = () => {
    setOpenEdit(true);
    setName(selectedRows[0].name);
    dispatch(getHospitalDetail(selectedID));
  };

  //handle page change
  const handlePageChange = (newPage) => {
    setPage(newPage);
    setLoading(true);
    setRows([]);
    dispatch(
      getHospital({
        page: newPage + 1,
        per_page: pageSize,
      })
    );
  };

  //handle page size change
  const handlePageSizeChange = (newPageSize) => {
    setPage(0);
    setPageSize(newPageSize);
    setLoading(true);
    dispatch(
      getHospital({
        page: 1,
        per_page: newPageSize,
      })
    );
  };

  return (
    <>
      <div className="pt-3">
        <LoadingWrapper loading={loading}>
          <MuiTable
            setSelectedID={setSelectedID}
            setSelectedRows={setSelectedRows}
            columns={columns}
            rows={rows}
            gridStyle={TableGridStyle}
            checkboxSelection
            singleSelected
            rowID="id"
            showAddButton
            showEditButton
            showDeleteButton
            handleDeleteRow={handleDeleteRow}
            handleAddNewRow={handleAddNewRow}
            handleEditRow={handleEditRow}
            rowsPerPageOptions={[5, 10, 15, 20, 50, 100]}
            pagination
            page={page}
            pageSize={pageSize}
            rowCount={rowCount}
            loading={loading}
            onPageSizeChange={handlePageSizeChange}
            onPageChange={handlePageChange}
            labelRowsPerPage={'Chicken'}
          />
        </LoadingWrapper>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle id="alert-dialog-title">Are you sure delete {name}</DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>No</Button>
            <Button onClick={handleConfirm} autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openAdd} maxWidth={'md'} fullWidth={true} onClose={handleAddNewClose}>
          <AddNewHospital handleClose={handleAddNewClose} />
        </Dialog>
        <Dialog open={openEdit} maxWidth={'md'} fullWidth={true} onClose={handleEditClose}>
          <EditHospital selectedID={selectedID} handleClose={handleEditClose} name={name} />
        </Dialog>
      </div>
    </>
  );
};
export default Hospital;
