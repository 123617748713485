import moment from 'moment';

const checkValidPhoneNumber = (phone) => {
  let validPhone = {
    isValidPhone: true,
    phoneNumber: phone,
  };
  const conditaion = /(\+84|84|0)+(3[2-9]|5[6|8|9]|9\d(?!5)|8[1-9]|7[0|6-9])+([0-9]{7})\b/g;
  if (!phone.match(conditaion)) {
    validPhone = {
      isValidPhone: false,
      phoneNumber: phone,
    };
  }

  return validPhone;
};

const upperFistCharacterOfString = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const translationFunction = (str, lang, arr) => {
  let transText = '';
  arr.forEach((item) => {
    if (str === item.value) {
      if (lang === 'en') {
        transText = item.name_en;
      } else {
        transText = item.name_vn;
      }
    }
  });

  return transText;
};

const splitNumberInsideString = (str) => {
  var split_string = str.split(/(\d+)/);
  var data = {
    text: split_string[0].trim(),
    number: split_string[1],
  };

  return data;
};

const formatDate = (value, type) => {
  if (!value) {
    return '';
  }

  const format = type ?? 'DD/MM/YYYY';

  const result = moment(value).format(format);

  return result !== 'Invalid date' ? result : '';
};

const objectIsEmpty = (value) => {
  if (!value || typeof value !== 'object') {
    return true;
  }

  return Object.keys(value).length === 0;
};

const formatMoney = (value) => {
  if (!value) {
    return '';
  }

  return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export {
  checkValidPhoneNumber,
  upperFistCharacterOfString,
  translationFunction,
  splitNumberInsideString,
  formatDate,
  objectIsEmpty,
  formatMoney,
};
