const editActionTypes = {
  GET_ITEM_EDIT: 'GET_ITEM_EDIT',
  UPDATE_ITEM_EDIT: 'UPDATE_ITEM_EDIT',
  EDIT_SERVICE_EXPIRE: 'EDIT_SERVICE_EXPIRE',
  SET_EDIT_SERVICE_EXPIRE: 'SET_EDIT_SERVICE_EXPIRE',
  EDIT_CHILDREN_SERVICE_EXPIRE: 'EDIT_CHILDREN_SERVICE_EXPIRE',
  SET_EDIT_CHILDREN_SERVICE_EXPIRE: 'SET_EDIT_CHILDREN_SERVICE_EXPIRE',
};

export default editActionTypes;
