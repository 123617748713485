import types from './packageActionTypes';

export const getPackages = (values) => ({
  type: types.GET_PACKAGES,
  values,
});

export const setPackages = (values) => ({
  type: types.SET_PACKAGES,
  values,
});

export const getPackageDetail = (values) => ({
  type: types.GET_PACKAGE_DETAIL,
  values,
});

export const setPackageDetail = (values) => ({
  type: types.SET_PACKAGE_DETAIL,
  values,
});

export const getPackageTypes = (values) => ({
  type: types.GET_PACKAGE_TYPES,
  values,
});

export const setPackageTypes = (values) => ({
  type: types.SET_PACKAGE_TYPES,
  values,
});
