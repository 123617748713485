import React from 'react';
import Logo from '../Logo';
import AvatarImg from '../../images/avatar/icadmin.jpg';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Space, Image } from 'antd';
import { SmileTwoTone } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import './header.scss';
import Search from '../Search';

const Header = () => {
  let name;

  const profileInfo = useSelector((state) => state.profileReducer.items.data);

  if (profileInfo.error_code === 0) {
    name = profileInfo.data.name;
  }

  const logout = () => {
    window.localStorage.clear();
    window.location.href = '/';
  };

  const ListMenu = (
    <div className="listMenu">
      <p className="mb-0 fw-bold d-flex align-items-center">
        <SmileTwoTone twoToneColor="#fa7015" className="me-sm" /> Hey, {name}
      </p>
      <ul>
        <li>
          <a href="#">Settings</a>
        </li>
        <li>
          <Link to={'/change-password'}>Change Password</Link>
        </li>
        <li>
          <a href="#" className="text-primary" onClick={logout}>
            Logout
          </a>
        </li>
      </ul>
    </div>
  );

  return (
    <>
      <div
        className="dashboard-head bg-white px-15px w-100 shadow-lg position-fixed top-0 start-0 z-2"
        style={{ height: '70px' }}
      >
        <div className="d-flex justify-content-between">
          <Logo />
          <div className="mt-15px d-flex align-items-center">
            {/* Search user */}
            <Search />

            <div className="ms-1">
              <Dropdown overlay={ListMenu} trigger={['click']}>
                <Space className="cursor-pointer border p-sm rounded-1">
                  <Image
                    preview={false}
                    className="rounded-circle cursor-pointer"
                    height={'28px'}
                    width={'28px'}
                    src={AvatarImg}
                    onClick={(e) => e.preventDefault()}
                  />
                  <span>{name}</span>
                  <DownOutlined style={{ marginBottom: '4px' }} />
                </Space>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Header;
