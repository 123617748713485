import types from './addHospitalActionTypes';

export const addHospitalStart = (values) => ({
  type: types.ADD_HOSPITAL_START,
  payload: values,
});

export const addHospitalSuccess = (values) => ({
  type: types.ADD_HOSPITAL_SUCCESS,
  payload: values,
});

export const addHospitalFailure = (error) => ({
  type: types.ADD_HOSPITAL_FAILURE,
  payload: error,
});
