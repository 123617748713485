import { all, call, put, takeLatest } from 'redux-saga/effects';
import apiInstance from '../../../../gateway/Instance';
import { message } from 'antd';

import { updateHospitalDelete } from '../deleteHospitalActions';

import types from '../deleteHospitalActionTypes';

const getHospitalDeleteAPI = async (deleteID) => {
  const response = await apiInstance
    .delete(`/hospital/${deleteID}`)
    .then((response) => {
      if (response.status === 200) {
        message.success('Deleted Hospital success');
      }
    })
    .catch((error) => console.log(error));
  return response;
};

export function* getDeleteHospitalCredentials({ payload: deleteID }) {
  try {
    yield put(updateHospitalDelete({ isLoading: true, status: false, isError: false }));
    yield getHospitalDeleteAPI(deleteID);
    yield put(updateHospitalDelete({ isLoading: false, status: true, isError: false }));
  } catch (error) {
    yield put(updateHospitalDelete({ isLoading: false, status: false, isError: true }));
  }
}

export function* onDeleteHospitalStart() {
  yield takeLatest(types.GET_HOSPITAL_DELETE, getDeleteHospitalCredentials);
}

export function* deleteHospitalSagas() {
  yield all([call(onDeleteHospitalStart)]);
}
