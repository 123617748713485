const addressActionTypes = {
  GET_PROVINCES: 'GET_PROVINCES',
  SHOW_PROVINCES: 'SHOW_PROVINCES',
  GET_DISTRICTS_BY_PROVINCE: 'GET_DISTRICTS_BY_PROVINCE',
  SHOW_DISTRICTS_BY_PROVINCE: 'SHOW_DISTRICTS_BY_PROVINCE',
  GET_WARDS: 'GET_WARDS',
  SHOW_WARDS: 'SHOW_WARDS',
};

export default addressActionTypes;
