import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
//import { registerStart } from "../../store/register/registerActions";
import { DialogContent, DialogTitle, IconButton, TextField } from '@mui/material';
import { Button } from '@mui/material';
import { getHospital } from '../../../../store/hospital/hospitalList/hospitalActions';
import { getHospitalEdit } from '../../../../store/hospital/editHospital/editHospitalActions';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import UploadFile from '../../../../components/UploadFile';
import CloseIcon from '@mui/icons-material/Close';

const EditHospital = ({ selectedID, handleClose, name }) => {
  const dispatch = useDispatch();
  const [hospitalDataDetail, setHospitalDataDetail] = useState([]);
  const [values, setValues] = useState([]);
  const [valuesDesc, setValuesDesc] = useState();
  const [fileLogoData, setFileLogoData] = useState({});
  const [imagesLogoData, setImagesLogoData] = useState([]);

  const [hospitalDetailData, editStatus] = useSelector((state) => [
    state.hospitalDetailReducer.items.data,
    state.editHospitalReducer.items,
  ]);

  useEffect(() => {
    if (hospitalDetailData.error_code === 0) {
      setHospitalDataDetail(hospitalDetailData.data);
    }
  }, [hospitalDetailData]);
  // set Value State
  useEffect(() => {
    if (hospitalDataDetail) {
      setValues({
        name: hospitalDataDetail?.name,
        short_desc: hospitalDataDetail?.short_desc,
      });
      setImagesLogoData([
        {
          name: hospitalDataDetail?.image,
        },
      ]);
    }
  }, [hospitalDataDetail]);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeEditor = (content) => {
    setValuesDesc(content);
  };

  const objectIsEmpty = (value) => {
    return Object.keys(value).length === 0;
  };

  const handleSubmit = () => {
    if (values.name === '') {
      alert('Please enter field Name');
      return;
    }
    //Handle submit success
    let dataEdit = { ...values, desc: valuesDesc };
    if (imagesLogoData.length === 0) {
      alert('Please add file Logo');
      return false;
    } else {
      dataEdit = { ...dataEdit, image: { name: fileLogoData.new_name } };
    }
    dispatch(
      getHospitalEdit({
        selectedID: selectedID[0],
        values: dataEdit,
      })
    );
  };

  const optionsSunEditor = {
    height: 200,
    setOptions: {
      katex: 'window.katex',
      tabDisable: false,
      videoFileInput: true,
      audioFileInput: true,
      linkTargetNewWindow: true,
      buttonList: [
        ['undo', 'redo'],
        ['font', 'fontSize', 'formatBlock'],
        ['paragraphStyle', 'blockquote'],
        ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
        ['fontColor', 'hiliteColor', 'textStyle'],
        ['outdent', 'indent'],
        ['align', 'horizontalRule', 'list', 'lineHeight'],
        ['table', 'link', 'image', 'video', 'audio', 'math'],
        ['fullScreen', 'showBlocks', 'codeView', 'preview'],
      ],
    },
  };

  // Configuration for upload image
  const propsLogo = {
    acceptFile: 'image/*',
    name: 'image',
    handleChange(info) {
      const files = info.fileList;
      if (files.length > 0 && files[0].status === 'done') {
        setFileLogoData(files[0].response.data);
      }
      setImagesLogoData(info.fileList);
    },
    handleRemove(event) {
      setFileLogoData({});
    },
    files: imagesLogoData,
  };

  return (
    <>
      <DialogTitle className="fw-bold">
        <span>Edit Hospital {name}</span>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div className="addNewEmployee_main fs-15 pt-2 pb-2">
          <form className="" autoComplete="off" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-12 col-lg-12 mb-2">
                <TextField
                  name="name"
                  value={values.name}
                  fullWidth
                  required
                  id="outlined-name"
                  label="Name"
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="col-12 col-lg-12 mb-2">
                <TextField
                  name="short_desc"
                  value={values.short_desc}
                  fullWidth
                  id="outlined-short_desc"
                  label="Short Desc"
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="col-12 col-lg-12 mb-2">
                <SunEditor
                  name="desc"
                  autoFocus={false}
                  {...optionsSunEditor}
                  //appendContents={hospitalDataDetail?.desc}
                  setContents={hospitalDetailData?.data?.desc}
                  onChange={handleChangeEditor}
                />
              </div>
              <div className="col-12 mb-2">
                <UploadFile {...propsLogo} />
              </div>
            </div>
            <div className="mw-930 mx-auto text-end">
              <Button
                color="primary"
                className="fw-bold"
                variant="contained"
                size="large"
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </div>
          </form>
        </div>
      </DialogContent>
    </>
  );
};
export default EditHospital;
