import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { Button, Form, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import LoadingWrapper from '../../../../../components/LoadingCustom/LoadingWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { objectIsEmpty } from '../../../../../utils/supportFunction';
import FormInput from '../../../../../components/ColFormItem/FormInput';
import FormSelect from '../../../../../components/ColFormItem/FormSelect';
import { getMember } from '../../../../../store/user/member/memberActions';
import { updateExaminationHistoryAPI } from '../../../../../store/examinationHistory/examinationHistorySagas';
import { getExaminationHistories } from '../../../../../store/examinationHistory/examinationHistoryActions';

const UpdateExaminationHistory = ({ showModal, setShowModal, paramsFilter }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const roleID = 6;

  const [doctors, setDoctors] = useState([]);
  const [childrenId, setChildrenId] = useState(null);

  const examinationDetail = useSelector(
    (state) => state.examinationHistoryReducer.examinationHistoryDetail
  );
  const doctorsReducer = useSelector((state) => state.memberReducer.items.data);

  useEffect(() => {
    dispatch(getMember({ roleID: roleID, page: 1, per_page: 99999 }));
  }, []);

  useEffect(() => {
    const dataTemp = examinationDetail.data;

    if (!objectIsEmpty(dataTemp)) {
      setChildrenId(dataTemp.children_id);

      form.setFieldsValue({
        name: dataTemp?.children_info?.name,
        doctor_id: dataTemp.doctor_id,
        diagnosis: dataTemp.diagnosis,
        treatment: dataTemp.treatment,
      });
    } else {
      form.resetFields();
    }
  }, [examinationDetail]);

  useEffect(() => {
    if (doctorsReducer.error_code === 0 && doctorsReducer.results.filter_by_role === roleID) {
      setDoctors(doctorsReducer.results.data);
    } else {
      setDoctors([]);
    }
  }, [doctorsReducer]);

  const renderOptionsDoctor = () => {
    if (!doctors.length) {
      return [];
    }

    return doctors.map((item) => ({ value: item.user_info?.id, label: item.name }));
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSubmit = async (values) => {
    const paramsUpdate = {
      children_id: childrenId,
      diagnosis: values.diagnosis,
      treatment: values.treatment,
      doctor_id: values.doctor_id,
    };

    return await updateExaminationHistoryAPI({
      id: examinationDetail.data.id,
      params: paramsUpdate,
    })
      .then((res) => {
        if (res.error_code === 0) {
          dispatch(getExaminationHistories(paramsFilter));

          form.resetFields();
          setShowModal(false);

          notification.success({
            message: 'Success',
            description: 'Successfully updated examine!',
          });
        } else {
          notification.error({
            message: 'Error',
            description: 'Edit examine failed!',
          });
        }
      })
      .catch((error) => {
        console.log('🚀 ~ file: index.js:97 ~ handleSubmit ~ error:', error);
        notification.error({
          message: 'Error',
          description: 'Edit examine failed!',
        });
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('🚀 ~ file: index.js:106 ~ onFinishFailed ~ errorInfo:', errorInfo);

    notification.error({
      message: 'Error',
      description: 'Edit examine failed!',
    });
  };

  return (
    <Dialog open={showModal} maxWidth={'md'} fullWidth={true} onClose={handleCloseModal}>
      <DialogTitle className="fw-bold">
        <span>Edit Examination History</span>
        <IconButton
          aria-label="close"
          onClick={handleCloseModal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <LoadingWrapper loading={examinationDetail.isLoading}>
          <div className="custom-form fs-15 pt-2">
            <Form
              form={form}
              name="updatePackage"
              onFinish={handleSubmit}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <div className="row">
                <FormInput disabled label="Name" name="name" classCol="col-12 col-lg-6" />
                <FormInput label="Diagnosis" name="diagnosis" classCol="col-12 col-lg-6" />
                <FormInput label="Treatment" name="treatment" classCol="col-12 col-lg-6" />
                <FormSelect
                  required
                  label="Doctor"
                  name="doctor_id"
                  options={renderOptionsDoctor()}
                  classCol="col-12 col-lg-6"
                />
              </div>
              <div className="mw-930 mx-auto text-end">
                <Button type="primary" className="fw-bold rounded" size="large" htmlType="submit">
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        </LoadingWrapper>
      </DialogContent>
    </Dialog>
  );
};

export default UpdateExaminationHistory;
