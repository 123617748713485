import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
//import { registerStart } from "../../store/register/registerActions";
import { DialogContent, DialogTitle, IconButton, TextField } from '@mui/material';
import { Button } from '@mui/material';
import { addHospitalStart } from '../../../../store/hospital/addHospital/addHospitalActions';
import { getHospital } from '../../../../store/hospital/hospitalList/hospitalActions';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import UploadFile from '../../../../components/UploadFile';
import CloseIcon from '@mui/icons-material/Close';

const AddNewHospital = ({ handleClose }) => {
  const dispatch = useDispatch();
  const [values, setValues] = useState(null);
  const [valuesDesc, setValuesDesc] = useState(null);
  const [fileLogoData, setFileLogoData] = useState({});
  const [imagesLogoData, setImagesLogoData] = useState([]);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeEditor = (content) => {
    setValuesDesc(content);
  };

  const objectIsEmpty = (value) => {
    return Object.keys(value).length === 0;
  };

  const handleSubmit = () => {
    if (values.name === '') {
      alert('Please enter field Name');
      return;
    }
    if (values.short_desc === '') {
      alert('Please enter field Short Desc');
      return;
    }
    // Handle submit success
    let dataCreate = { ...values, desc: valuesDesc };
    if (Object.keys(fileLogoData).length === 0) {
      alert('Please add file Logo');
      return false;
    } else {
      dataCreate = { ...dataCreate, image: { name: fileLogoData.new_name } };
    }
    dispatch(addHospitalStart(dataCreate));
  };

  const optionsSunEditor = {
    height: 200,
    setOptions: {
      katex: 'window.katex',
      tabDisable: false,
      videoFileInput: true,
      audioFileInput: true,
      linkTargetNewWindow: true,
      buttonList: [
        ['undo', 'redo'],
        ['font', 'fontSize', 'formatBlock'],
        ['paragraphStyle', 'blockquote'],
        ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
        ['fontColor', 'hiliteColor', 'textStyle'],
        ['outdent', 'indent'],
        ['align', 'horizontalRule', 'list', 'lineHeight'],
        ['table', 'link', 'image', 'video', 'audio', 'math'],
        ['fullScreen', 'showBlocks', 'codeView', 'preview'],
      ],
    },
  };

  // Configuration for upload image
  const propsLogo = {
    acceptFile: 'image/*',
    name: 'image',
    handleChange(info) {
      const files = info.fileList;
      if (files.length > 0 && files[0].status === 'done') {
        setFileLogoData(files[0].response.data);
      }
      setImagesLogoData(info.fileList);
    },
    handleRemove(event) {
      setFileLogoData({});
    },
    files: imagesLogoData,
  };

  return (
    <>
      <DialogTitle className="fw-bold">
        <span>Add New Hospital</span>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div className="addNewHospital_main fs-15 pt-2 pb-2">
          <form className="" autoComplete="off" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-12 col-lg-12 mb-2">
                <TextField
                  name="name"
                  fullWidth
                  required
                  id="outlined-name"
                  label="Name"
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="col-12 col-lg-12 mb-2">
                <TextField
                  name="short_desc"
                  fullWidth
                  id="outlined-short_desc"
                  label="Short Desc"
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="col-12 col-lg-12 mb-2">
                <SunEditor
                  name="desc"
                  onChange={handleChangeEditor}
                  {...optionsSunEditor}
                  autoFocus={false}
                />
              </div>
              <div className="col-12 mb-2">
                <UploadFile {...propsLogo} />
              </div>
            </div>
            <div className="mw-930 mx-auto text-end">
              <Button
                color="primary"
                className="fw-bold"
                variant="contained"
                size="large"
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </div>
          </form>
        </div>
      </DialogContent>
    </>
  );
};
export default AddNewHospital;
