import types from './addressActionTypes';

export const getProvinces = (values) => ({
  type: types.GET_PROVINCES,
  payload: values,
});

export const showProvinces = (values) => ({
  type: types.SHOW_PROVINCES,
  payload: values,
});

export const getDistrictsByProvince = (values) => ({
  type: types.GET_DISTRICTS_BY_PROVINCE,
  payload: values,
});

export const showDistrictsByProvince = (values) => ({
  type: types.SHOW_DISTRICTS_BY_PROVINCE,
  payload: values,
});

export const getWards = (values) => ({
  type: types.GET_WARDS,
  payload: values,
});

export const showWards = (values) => ({
  type: types.SHOW_WARDS,
  payload: values,
});
