import React, { useEffect, useState } from 'react';
import { Dropdown } from 'antd';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  searchUsers,
  setSelectedUser,
  showSearchUsers,
} from '../../store/user/searchUsers/searchUsersActions';
import { IconButton, InputBase, Paper, Skeleton } from '@mui/material';
import imageDefault from '../../images/avatar/icadmin.jpg';
import './search.scss';

const Search = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const [pathname, setPathname] = useState(location.pathname);
  const [roleUser, setRoleUser] = useState(null);
  const [valueSearch, setValueSearch] = useState('');
  const [isOpenSearch, setIsOpenSearch] = useState(false);
  const [showButtonSearch, setShowButtonSearch] = useState(false);

  const resultSearchUsers = useSelector((state) => state.searchUsersReducer.items);
  const childrenType = useSelector((state) => state.searchUsersReducer.childrenType);

  useEffect(() => {
    switch (location.pathname) {
      case '/employee-management':
        setRoleUser(1);
        setShowButtonSearch(true);
        break;

      case '/employee':
        setRoleUser(2);
        setShowButtonSearch(true);
        break;

      case '/children':
        setRoleUser(3);
        setShowButtonSearch(true);
        break;

      case '/supervisor':
        setRoleUser(4);
        setShowButtonSearch(true);
        break;

      case '/bodyguard':
        setRoleUser(5);
        setShowButtonSearch(true);
        break;

      default:
        setShowButtonSearch(false);
        break;
    }

    if (location.pathname !== pathname) {
      setPathname(location.pathname);
      setValueSearch('');
      dispatch(
        showSearchUsers({ isLoading: false, data: [], isError: false, isClearSearch: false })
      );
      dispatch(setSelectedUser({ status: false, role: null, userId: null }));
    }
  }, [location]);

  const onChangeOpenSearch = (open) => {
    setIsOpenSearch(open);
  };

  const onChangeSearch = (value) => {
    if (!value) {
      setValueSearch('');
      dispatch(
        showSearchUsers({ isLoading: false, data: [], isError: false, isClearSearch: true })
      );

      return;
    }

    setValueSearch(value);
  };

  const handleSearch = () => {
    if (!valueSearch) {
      return;
    }

    dispatch(
      searchUsers({
        search: valueSearch ?? null,
        params: { role: roleUser, children_type: roleUser === 3 ? childrenType : null },
      })
    );
  };

  // Generate Height of search users
  const generateHeight = (length, minHeight) => {
    if (length < 5) {
      return {
        height: length * minHeight + 10,
      };
    }

    return {
      height: 4 * minHeight + 10,
      overflowY: 'scroll',
    };
  };

  const handleClearSearch = () => {
    dispatch(showSearchUsers({ isLoading: false, data: [], isError: false, isClearSearch: true }));

    setValueSearch('');
  };

  const handleShowUpdate = (userId) => {
    setIsOpenSearch(false);
    dispatch(setSelectedUser({ status: true, role: roleUser, userId }));
  };

  const renderSearchResult = () => {
    if (resultSearchUsers.isLoading) {
      return (
        <div className="d-flex align-items-start px-2 py-1 w-100">
          <div>
            <Skeleton animation="wave" variant="circular" width={40} height={40} />
          </div>
          <div className="ms-1 w-100">
            <Skeleton animation="wave" height={10} width="60%" style={{ marginBottom: 6 }} />
            <Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }} />
            <Skeleton animation="wave" height={10} width="80%" />
          </div>
        </div>
      );
    }

    return resultSearchUsers.data.length ? (
      <div className="py-1" style={generateHeight(resultSearchUsers.data.length, 86)}>
        {resultSearchUsers.data.map((user, index) => {
          const parentInfo = user?.user_info?.parents[0]?.user_info || {};

          return (
            <div
              key={index}
              className="d-flex align-items-start px-2 py-1 cursor-pointer"
              onClick={() => handleShowUpdate(user.id)}
            >
              <img
                width={'35px'}
                className="cursor-pointer rounded-circle"
                src={user.image ? user.image : imageDefault}
                alt="Search"
              />
              <div className="d-flex flex-column ms-1 item-search">
                <span className="name-member">{user.name}</span>
                <span>{parentInfo?.phone}</span>
                <span>{parentInfo?.email}</span>
              </div>
            </div>
          );
        })}
      </div>
    ) : (
      <div className="d-flex justify-content-center align-items-center p-2">
        <span style={{ color: '#a0a0a0' }}>Không có dữ liệu!</span>
      </div>
    );
  };

  const dropdownSearch = () => {
    return (
      <Paper sx={{ width: 350 }}>
        <Paper sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}>
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            value={valueSearch}
            placeholder="Search"
            autoFocus
            inputProps={{ 'aria-label': 'Search' }}
            onChange={(e) => onChangeSearch(e.target.value)}
          />
          {resultSearchUsers.data?.length > 0 && (
            <IconButton onClick={handleClearSearch} sx={{ p: '10px' }} aria-label="search">
              <ClearIcon />
            </IconButton>
          )}
          <IconButton onClick={handleSearch} sx={{ p: '10px' }} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Paper>

        {renderSearchResult()}
      </Paper>
    );
  };

  if (!showButtonSearch) {
    return <div />;
  }

  return (
    <div>
      <div className="head_search">
        {/* Search result */}
        <Dropdown
          open={isOpenSearch}
          onOpenChange={onChangeOpenSearch}
          dropdownRender={dropdownSearch}
          trigger={['click']}
          autoFocus={true}
        >
          <div className="cursor-pointer">
            <SearchIcon />
          </div>
        </Dropdown>
      </div>
    </div>
  );
};
export default Search;
