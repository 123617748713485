import { Form, Select } from 'antd';
import React from 'react';

const FormSelect = ({
  label,
  name,
  mode,
  showSearch,
  defaultValue,
  disabled,
  onChange,
  options = [],
  renderOptions,
  filterOption,
  filterByChildren,
  classCol,
  classNameSelect = '',
  required,
  placeholder,
  propFI,
  propSelect,
}) => {
  // mode= "multiple" || "tags"

  const classNameDefault = `rounded ${classNameSelect}`;

  const handleFilterOption = (input, option) => {
    if (!filterByChildren) return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  return (
    <div className={classCol}>
      <label className="mb-sm">
        {label}:{required ? <span className="text-red"> *</span> : ''}
      </label>
      <Form.Item
        name={name}
        rules={[
          {
            required: required ?? false,
            message: `${label} field is required!`,
          },
        ]}
        {...propFI}
      >
        <Select
          mode={mode}
          showSearch={showSearch ?? false}
          options={options ?? undefined}
          defaultValue={defaultValue}
          disabled={disabled ?? false}
          style={{ width: '100%' }}
          onChange={onChange}
          filterOption={(inputValue, option) => handleFilterOption(inputValue, option)}
          placeholder={placeholder ?? `Select...`}
          className={classNameDefault}
          {...propSelect}
        >
          {renderOptions}
        </Select>
      </Form.Item>
    </div>
  );
};

export default FormSelect;
