import React, { useEffect, useState } from 'react';
import SignIn from './pages/SignIn';
import DashBoard from './pages/DashBoard';
import './scss/App.scss';
import './scss/table.scss';
import 'antd/dist/antd.min.css';
import { useDispatch, useSelector } from 'react-redux';
import { logInSuccess } from './store/auth/authActions';
import { getProfile } from './store/profile/profileActions';

function App() {
  const auth = useSelector((state) => state.auth.currentUser);
  const profileInfo = useSelector((state) => state.profileReducer.items);

  const [open, setOpen] = useState(true);

  const dispatch = useDispatch();
  let accessToken = localStorage.getItem('access_token');
  useEffect(() => {
    if (profileInfo.isError === true && profileInfo.isLoading === false) {
      window.localStorage.clear();
      window.location.href = '/';
    }
  }, [profileInfo]);

  useEffect(() => {
    dispatch(logInSuccess(accessToken));
  }, [accessToken]);

  useEffect(() => {
    if (accessToken) {
      dispatch(getProfile());
    }
  }, [accessToken, getProfile]);

  return !auth ? (
    <SignIn />
  ) : (
    <>
      <DashBoard />
    </>
  );
}

export default App;
