import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//import { registerStart } from "../../store/register/registerActions";
import CloseIcon from '@mui/icons-material/Close';
import { DialogContent, DialogTitle, IconButton, TextField } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { Button, Form, Select } from 'antd';
import LoadingWrapper from '../../../../components/LoadingCustom/LoadingWrapper';
import { getProvinces } from '../../../../store/address/addressActions';
import {
  addMemberStart,
  addMemberSuccess,
} from '../../../../store/user/addMember/addMemberActions';
import { getMember } from '../../../../store/user/member/memberActions';

const AddNewEmployeeManagement = ({ pageSize, setLoadingList, setOpenAdd, handleClose }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const roleID = 1;

  const [checkedVerify, setCheckedVerify] = useState(false);
  const [checkedIsSuperAdmin, setCheckedIsSuperAdmin] = useState(false);
  const [loading, setLoading] = useState(false);

  const addMemberStatus = useSelector((state) => state.addMemberReducer.items);
  const provinces = useSelector((state) => state.addressReducer.provinces);

  useEffect(() => {
    dispatch(getProvinces());
  }, []);

  useEffect(() => {
    if (addMemberStatus.status === true) {
      setLoadingList(true);
      setLoading(false);
      setOpenAdd(false);

      dispatch(getMember({ roleID: roleID, page: 1, per_page: pageSize }));
      resetStatusAction();
    } else if (!addMemberStatus.status && addMemberStatus.isError) {
      setLoading(false);
      resetStatusAction();
    }
  }, [addMemberStatus, dispatch]);

  const renderOptionsProvinces = useMemo(() => {
    return provinces?.data?.map((item) => ({
      label: item.name,
      value: item.id,
    }));
  }, [provinces]);

  const resetStatusAction = () => {
    return dispatch(addMemberSuccess({ isLoading: false, status: false, isError: false }));
  };

  const handleCheckIsSuperAdmin = (event) => {
    setCheckedIsSuperAdmin(event.target.checked);
  };

  const handleCheckVerified = (event) => {
    setCheckedVerify(event.target.checked);
  };

  const handleSubmit = (values) => {
    if (!values.name) {
      alert('Please enter field FullName');
      return;
    } else if (!values.password) {
      alert('Please enter field Password');
      return;
    } else if (!values.phone) {
      alert('Please enter field Phone');
      return;
    } else if (!values.email) {
      alert('Please enter field Email');
      return;
    } else if (values?.provinces?.length <= 0) {
      alert('Please enter field Provinces');
      return;
    }

    setLoading(true);

    const paramsSubmit = {
      ...values,
      role: `${roleID}`,
      user_verified: checkedVerify ? 1 : 0,
      admin_info: { is_super_admin: checkedIsSuperAdmin ? 1 : 0 },
    };

    // Handle submit success
    dispatch(addMemberStart(paramsSubmit));
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      <DialogTitle className="fw-bold">
        <span>Add New Employee</span>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <LoadingWrapper loading={loading}>
          <div className="addNewEmployee_main fs-15 pt-2">
            <Form
              form={form}
              name="createEmployee"
              onFinish={handleSubmit}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <div className="row">
                <div className="col-12 col-lg-6">
                  <Form.Item name="name">
                    <TextField
                      name="name"
                      fullWidth
                      required
                      id="outlined-fullname"
                      label="Fullname"
                    />
                  </Form.Item>
                </div>
                <div className="col-12 col-lg-6">
                  <Form.Item name="password">
                    <TextField
                      name="password"
                      type={'password'}
                      fullWidth
                      required
                      id="outlined-password"
                      label="Password"
                      autoComplete="off"
                    />
                  </Form.Item>
                </div>
                <div className="col-12 col-lg-6">
                  <Form.Item name="phone">
                    <TextField name="phone" fullWidth required id="phone" label="Phone" />
                  </Form.Item>
                </div>
                <div className="col-12 col-lg-6">
                  <Form.Item name="email">
                    <TextField
                      name="email"
                      required
                      fullWidth
                      id="outlined-email"
                      label="Email"
                      autoComplete="off"
                    />
                  </Form.Item>
                </div>
                <div className="col-12 col-lg-6">
                  <Form.Item name="address">
                    <TextField name="address" fullWidth id="outlined-address" label="Address" />
                  </Form.Item>
                </div>

                <div className="col-12 col-md-6">
                  <Form.Item
                    name={'provinces'}
                    rules={[
                      {
                        required: true,
                        message: 'Missing province',
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      mode="multiple"
                      label={'Provinces'}
                      placeholder="Select a provinces *"
                      optionFilterProp="children"
                      loading={provinces.isLoading}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={renderOptionsProvinces}
                      className="ant-select-custom"
                    />
                  </Form.Item>
                </div>

                <div className="col-12 col-lg-6">
                  <Form.Item name="isSuperAdmin">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox onChange={handleCheckIsSuperAdmin} name="isSuperAdmin" />
                        }
                        label="Is super admin"
                      />
                    </FormGroup>
                  </Form.Item>
                </div>

                <div className="col-12 col-lg-6">
                  <Form.Item name="user_verified">
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox onChange={handleCheckVerified} name="user_verified" />}
                        label="User Verified"
                      />
                    </FormGroup>
                  </Form.Item>
                </div>
              </div>
              <div className="mw-930 mx-auto text-end">
                <Form.Item>
                  <Button type="primary" className="fw-bold rounded" size="large" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
        </LoadingWrapper>
      </DialogContent>
    </>
  );
};
export default AddNewEmployeeManagement;
