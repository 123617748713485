import { Spin } from 'antd';
import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';

const LoadingWrapper = ({ children, loading, icon, props = {} }) => {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <Spin spinning={loading} tip="Loading..." indicator={icon ?? antIcon} {...props}>
      {children}
    </Spin>
  );
};

export default LoadingWrapper;
