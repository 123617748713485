import React from "react";
import "../dashboard.scss";

const TrackingArea = () => {
  return (
    <>
      <div>Tracking Area</div>
    </>
  );
};
export default TrackingArea;
