import apiInstance from '../../../../gateway/Instance';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import { showHospital } from '../hospitalActions';

import types from '../hospitalActionTypes';

// const getHospitalAPI = async () => {
//   const response = apiInstance.get(`/hospital`);
//   return response;
// };

const getHospitalAPI = async ({ page, per_page }) => {
  const response = apiInstance.get(`/hospital`, {
    params: {
      page: page,
      per_page: per_page,
    },
  });
  return response;
};

export function* getHospitalCredentials({ payload }) {
  try {
    yield put(showHospital({ isLoading: true, data: [], isError: false }));
    const result = yield call(getHospitalAPI, payload);
    yield put(showHospital({ isLoading: false, data: result, isError: false }));
  } catch (error) {
    yield put(showHospital({ isLoading: false, data: [], isError: true }));
  }
}

export function* onHospitalStart() {
  yield takeEvery(types.GET_HOSPITAL, getHospitalCredentials);
}

export function* hospitalSagas() {
  yield all([call(onHospitalStart)]);
}
