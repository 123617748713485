import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogContent, DialogTitle, IconButton, TextField } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { Spin, message, notification } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import {
  editChildrenServiceExpire,
  setEditChildrenServiceExpire,
} from '../../../../store/user/editMember/editMemberActions';
import { getMember } from '../../../../store/user/member/memberActions';

const EditChildrenServiceExpire = ({
  name,
  page,
  pageSize,
  openModal,
  setOpenModal,
  setLoadingList,
}) => {
  const dispatch = useDispatch();

  const roleID = 3;

  const [memberDataDetail, setMemberDataDetail] = useState({});
  const [values, setValues] = useState({});
  const [valueServiceExpire, setValueServiceExpire] = useState(null);
  const [loading, setLoading] = useState(true);

  const memDetailData = useSelector((state) => state.memberDetailReducer.items.data);
  const editStatus = useSelector(
    (state) => state.editMemberReducer.actionEditChildrenServiceExpire
  );

  useEffect(() => {
    if (memDetailData.error_code === 0) {
      const infoData = memDetailData.data;
      if (infoData) {
        const serviceExpire = openModal.serviceExpire ? moment(openModal.serviceExpire) : null;
        setValueServiceExpire(serviceExpire);

        setMemberDataDetail(infoData);
        setValues({
          name: infoData.name,
        });
      }

      setLoading(false);
    } else if (memDetailData.error_code === 1) {
      notification.error({ message: 'Error', description: memDetailData?.error_description });
      setLoading(false);
      setOpenModal({ show: false, serviceExpire: null });
    }
  }, [memDetailData]);

  useEffect(() => {
    if (editStatus.status === true) {
      setLoadingList(true);
      setLoading(true);
      setOpenModal({ show: false, serviceExpire: null });
      setMemberDataDetail({});
      setValues({});

      dispatch(getMember({ roleID: roleID, page: page, per_page: pageSize }));
      dispatch(setEditChildrenServiceExpire({ isLoading: false, status: false, isError: false }));
      message.success('Updated service expire success!');
    } else if (!editStatus.status && editStatus.isError) {
      setLoading(false);
    }
  }, [editStatus]);

  const handleCloseModal = () => {
    setValues({});
    setLoading(true);
    setOpenModal({ show: false, serviceExpire: null });
    setMemberDataDetail({});
    setValueServiceExpire(null);
  };

  const handleSubmit = () => {
    if (!valueServiceExpire) {
      alert('Please, enter field Service Expire At.');
      return;
    }

    setLoading(true);

    const valueEdit = {
      user_id: memberDataDetail.id,
      time_expired: moment(valueServiceExpire).format('YYYY-MM-DD HH:mm:ss'),
    };

    // Handle submit success
    dispatch(
      editChildrenServiceExpire({
        values: valueEdit,
      })
    );
  };

  return (
    <Dialog open={openModal.show} maxWidth={'md'} fullWidth={true} onClose={handleCloseModal}>
      <DialogTitle className="fw-bold">
        <span>Edit Children Time "{name}"</span>
        <IconButton
          aria-label="close"
          onClick={handleCloseModal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Spin spinning={loading}>
          <div className="addNewSupervisor_main fs-15 pt-2 pb-2">
            <form autoComplete="off" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-12 col-lg-6 mb-2">
                  <TextField
                    name="name"
                    value={values.name}
                    fullWidth
                    disabled
                    id="outlined-fullname"
                    label="Fullname"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className="col-12 col-lg-6 mb-2">
                  <LocalizationProvider fullWidth dateAdapter={AdapterMoment}>
                    <DateTimePicker
                      required
                      id="outlined-service-expire-at"
                      label="Service Expire At"
                      format="YYYY-MM-DD HH:mm:ss"
                      inputFormat="YYYY-MM-DD HH:mm:ss"
                      className="w-100"
                      value={valueServiceExpire}
                      onChange={(newValue) => setValueServiceExpire(newValue)}
                    />
                  </LocalizationProvider>
                </div>
              </div>
              <div className="mw-930 mx-auto text-end">
                <Button
                  color="primary"
                  className="fw-bold"
                  variant="contained"
                  size="large"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </Spin>
      </DialogContent>
    </Dialog>
  );
};

export default EditChildrenServiceExpire;
