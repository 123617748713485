import { notification } from 'antd';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import apiInstance from '../../../../gateway/Instance';

import { addMemberSuccess } from '../addMemberActions';

import types from '../addMemberActionTypes';

const addMemberAPI = async (values) => {
  const response = await apiInstance
    .post(`/user`, values)
    .then((res) => {
      if (!res.error_code) {
        notification.success({ message: 'Success', description: 'Created user successful!' });
        return res;
      }

      throw res;
    })
    .catch((error) => {
      const messageError = error.error_description;
      notification.error({
        message: 'Error',
        description: messageError ? messageError : error.response?.data?.error_description,
      });

      throw error;
    });
  return response;
};

export function* addMemberWithCredentials({ payload: values }) {
  try {
    yield put(addMemberSuccess({ isLoading: true, status: false, isError: false }));
    yield addMemberAPI(values);
    yield put(addMemberSuccess({ isLoading: false, status: true, isError: false }));
  } catch (error) {
    console.log('🚀 ~ file: index.js:35 ~ function*addMemberWithCredentials ~ error:', error);
    yield put(addMemberSuccess({ isLoading: false, status: false, isError: true }));
  }
}

export function* onAddMemberStart() {
  yield takeLatest(types.ADD_MEMBER_START, addMemberWithCredentials);
}

export function* addMemberSagas() {
  yield all([call(onAddMemberStart)]);
}
