/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//import { registerStart } from "../../store/register/registerActions";
import CloseIcon from '@mui/icons-material/Close';
import { DialogContent, DialogTitle, IconButton } from '@mui/material';
import { Button, Checkbox, Form, message, notification } from 'antd';
import FormInput from '../../../../components/ColFormItem/FormInput';
import { getItemEdit, updateItemEdit } from '../../../../store/user/editMember/editMemberActions';
import { getMember } from '../../../../store/user/member/memberActions';
import '../../dashboard.scss';
import LoadingWrapper from '../../../../components/LoadingCustom/LoadingWrapper';

const UpdateDoctor = ({ name, page, pageSize, setLoadingList, handleClose, setOpenEdit }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const roleID = 6;

  const [loading, setLoading] = useState(true);
  const [memberDataDetail, setMemberDataDetail] = useState({});

  const memDetailData = useSelector((state) => state.memberDetailReducer.items.data);
  const editStatus = useSelector((state) => state.editMemberReducer.items);

  useEffect(() => {
    if (memDetailData.error_code === 0) {
      const infoData = memDetailData.data;

      setMemberDataDetail(infoData);

      form.setFieldsValue({
        name: infoData.name,
        address: infoData.address,
        phone: infoData.phone,
        email: infoData.email,
        user_verified: infoData.user_verified,
      });

      setLoading(false);
    } else if (memDetailData.error_code === 1) {
      setLoading(false);
      handleClose();
    }
  }, [memDetailData]);

  useEffect(() => {
    if (editStatus.status === true) {
      setLoadingList(true);
      setLoading(false);
      setOpenEdit(false);

      dispatch(getMember({ roleID: roleID, page: page, per_page: pageSize }));
      dispatch(updateItemEdit({ isLoading: false, status: false, isError: false }));

      message.success('Updated doctor successfully!');
    } else if (!editStatus.status && editStatus.isError) {
      setLoading(false);
      message.error('Update doctor failed!');
    }
  }, [editStatus]);

  const handleSubmit = (values) => {
    setLoading(true);

    const paramsSubmit = {
      name: values.name,
      password: values.password,
      address: values.address,
      type: values.type,
      user_verified: values.user_verified ? 1 : 0,
    };

    // Handle submit success
    dispatch(
      getItemEdit({
        selectedID: memberDataDetail?.id,
        values: paramsSubmit,
      })
    );
  };

  const onFinishFailed = (errorInfo) => {
    notification.error({
      message: 'Error',
      description: 'Edit employee failed!',
    });
  };

  return (
    <>
      <DialogTitle className="fw-bold">
        <span>Edit Doctor "{name}"</span>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <LoadingWrapper loading={loading}>
          <div className="custom-form fs-15 pt-2 pb-2">
            <Form
              form={form}
              name="updateDoctor"
              onFinish={handleSubmit}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <div className="row">
                <FormInput required label="Full Name" name="name" classCol="col-12 col-lg-6" />
                <FormInput
                  required
                  type="password"
                  label="Password"
                  name="password"
                  classCol="col-12 col-lg-6"
                />
                <FormInput
                  required
                  disabled
                  label="Phone"
                  name="phone"
                  classCol="col-12 col-lg-6"
                />
                <FormInput
                  required
                  disabled
                  label="Email"
                  name="email"
                  classCol="col-12 col-lg-6"
                />
                <FormInput required label="Address" name="address" classCol="col-12 col-lg-6" />
                <div className="col-12 col-lg-6">
                  <Form.Item name="user_verified" valuePropName="checked" className="mt-3">
                    <Checkbox>User Verified</Checkbox>
                  </Form.Item>
                </div>
              </div>
              <div className="mw-930 mx-auto text-end">
                <Button type="primary" className="fw-bold rounded" size="large" htmlType="submit">
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        </LoadingWrapper>
      </DialogContent>
    </>
  );
};
export default UpdateDoctor;
