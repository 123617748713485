import types from './editMemberActionTypes';

const initialState = {
  items: { isLoading: true, status: false, isError: false },
  actionEditServiceExpire: { isLoading: true, status: false, isError: false },
  actionEditChildrenServiceExpire: { isLoading: false, status: false, isError: false },
};

const editMemberReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_ITEM_EDIT:
      return {
        ...state,
        items: action.payload,
      };
    case types.SET_EDIT_SERVICE_EXPIRE:
      return {
        ...state,
        actionEditServiceExpire: action.payload,
      };
    case types.SET_EDIT_CHILDREN_SERVICE_EXPIRE:
      return {
        ...state,
        actionEditChildrenServiceExpire: action.payload,
      };
    default:
      return state;
  }
};

export default editMemberReducer;
