import types from "./memberActionTypes";

export const getMember = (values) => ({
  type: types.GET_MEMBER,
  payload: values,
});

export const showMember = (values) => ({
  type: types.SHOW_MEMBER,
  payload: values,
});
