import axios from 'axios';

import { AUTHORIZATION_KEY, API_CONFIGS } from '../utils/configs';
import { logOut } from '../store/auth/authActions';

const apiInstance = axios.create({
  baseURL: API_CONFIGS.API_BASE_URL + API_CONFIGS.API_BASE_VER,
  timeout: 3000 * 1000,
});

apiInstance.interceptors.request.use(function (config) {
  let accessToken = localStorage.getItem(AUTHORIZATION_KEY.ACCESS_TOKEN);

  if (accessToken) {
    config.headers['Authorization'] = 'Bearer ' + accessToken;
  }

  return config;
});

apiInstance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    logOut();
    return Promise.reject(error);
  }
);

export default apiInstance;
