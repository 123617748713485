export const INITIAL_STATE_REDUCER = {
  ARRAY: { isLoading: false, data: [], isError: false },
  ARRAY_LOADING: { isLoading: true, data: [], isError: false },
  ARRAY_ERROR: { isLoading: false, data: [], isError: true },
  OBJECT: { isLoading: false, data: {}, isError: false },
  OBJECT_LOADING: { isLoading: true, data: {}, isError: false },
  OBJECT_ERROR: { isLoading: false, data: {}, isError: true },
  ACTIONS: { isLoading: false, create: false, update: false, delete: false, isError: false },
  ACTIONS_LOADING: { isLoading: true, create: false, update: false, delete: false, isError: false },
  ACTIONS_ERROR: { isLoading: false, create: false, update: false, delete: false, isError: true },
  ACTIONS_CREATE: { isLoading: false, create: true, update: false, delete: false, isError: false },
  ACTIONS_UPDATE: { isLoading: false, create: false, update: true, delete: false, isError: false },
  ACTIONS_DELETE: { isLoading: false, create: false, update: false, delete: true, isError: false },
};
