import types from './hospitalActionTypes';

export const getHospital = (values) => ({
  type: types.GET_HOSPITAL,
  payload: values,
});

export const showHospital = (values) => ({
  type: types.SHOW_HOSPITAL,
  payload: values,
});
