import types from './editMemberActionTypes';

export const getItemEdit = (selectedID) => ({
  type: types.GET_ITEM_EDIT,
  payload: selectedID,
});

export const updateItemEdit = (selectedID) => ({
  type: types.UPDATE_ITEM_EDIT,
  payload: selectedID,
});

export const editServiceExpire = (selectedID) => ({
  type: types.EDIT_SERVICE_EXPIRE,
  payload: selectedID,
});

export const setEditServiceExpire = (selectedID) => ({
  type: types.SET_EDIT_SERVICE_EXPIRE,
  payload: selectedID,
});

export const editChildrenServiceExpire = (values) => ({
  type: types.EDIT_CHILDREN_SERVICE_EXPIRE,
  payload: values,
});

export const setEditChildrenServiceExpire = (values) => ({
  type: types.SET_EDIT_CHILDREN_SERVICE_EXPIRE,
  payload: values,
});
