import { all, call, put, takeEvery } from 'redux-saga/effects';
import apiInstance from '../../../gateway/Instance';
import apiInstanceMain from '../../../gateway/InstanceMain';

import types from '../addressActionTypes';
import { showDistrictsByProvince, showProvinces, showWards } from '../addressActions';

const getProvincesAPI = async () => {
  const response = apiInstance.get(`/provinces`);
  return response;
};

const getDistrictsByProvinceAPI = async (params) => {
  const response = apiInstance.get(`/districts`, { params });
  return response;
};

const getWardsAPI = async (params) => {
  const response = apiInstanceMain.get(`/wards`, { params });
  return response;
};

// =================================================================

export function* getProvincesCredentials({ payload }) {
  try {
    yield put(showProvinces({ isLoading: true, data: [], isError: false }));
    const result = yield call(getProvincesAPI, payload);
    yield put(showProvinces({ isLoading: false, data: result, isError: false }));
  } catch (error) {
    console.log('🚀 ~ index.js:27 ~ getProvincesCredentials ~ error:', error);
    yield put(showProvinces({ isLoading: false, data: [], isError: true }));
  }
}

export function* getDistrictsByProvinceCredentials({ payload }) {
  try {
    yield put(showDistrictsByProvince({ isLoading: true, data: [], isError: false }));
    const result = yield call(getDistrictsByProvinceAPI, payload);
    yield put(showDistrictsByProvince({ isLoading: false, data: result, isError: false }));
  } catch (error) {
    console.log('🚀 ~ index.js:27 ~ getDistrictsByProvinceCredentials ~ error:', error);
    yield put(showDistrictsByProvince({ isLoading: false, data: [], isError: true }));
  }
}

export function* getWardsCredentials({ payload }) {
  try {
    yield put(showWards({ isLoading: true, data: [], isError: false }));
    const result = yield call(getWardsAPI, payload);
    yield put(showWards({ isLoading: false, data: result.data, isError: false }));
  } catch (error) {
    console.log('🚀 ~ index.js:27 ~ getWardsCredentials ~ error:', error);
    yield put(showWards({ isLoading: false, data: [], isError: true }));
  }
}

export function* onAddressStart() {
  yield takeEvery(types.GET_PROVINCES, getProvincesCredentials);
  yield takeEvery(types.GET_DISTRICTS_BY_PROVINCE, getDistrictsByProvinceCredentials);
  yield takeEvery(types.GET_WARDS, getWardsCredentials);
}

export function* addressSagas() {
  yield all([call(onAddressStart)]);
}
