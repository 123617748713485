import types from './profileActionTypes';

export const getProfile = (values) => ({
  type: types.GET_PROFILE,
  payload: values,
});

export const showProfile = (values) => ({
  type: types.SHOW_PROFILE,
  payload: values,
});
