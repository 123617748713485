import React from "react";
import "../dashboard.scss";

const Setting = () => {
  return (
    <>
      <div>Setting</div>
    </>
  );
};
export default Setting;
