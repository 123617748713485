import { all, call, put, takeLatest } from 'redux-saga/effects';
import apiInstance from '../../../gateway/InstanceMain';
import types from '../orderActionTypes';
import { setHistories } from '../orderActions';

const getHistoriesAPI = async ({
  column,
  sortType,
  page,
  per_page,
  startDate,
  endDate,
  status,
}) => {
  // filters[column]: id/create_at
  // filters[direction]: asc/desc
  // status: paid/unpaid

  const response = apiInstance.get(`/order`, {
    params: {
      'filters[column]': column,
      'filters[direction]': sortType,
      page: page,
      per_page: per_page,
      start_date: startDate,
      end_date: endDate,
      status: status,
    },
  });
  return response;
};

export function* getHistoriesCredentials({ payload }) {
  try {
    yield put(setHistories({ isLoading: true, data: [], isError: false }));
    const result = yield call(getHistoriesAPI, payload);
    yield put(setHistories({ isLoading: false, data: result, isError: false }));
  } catch (error) {
    console.log('🚀 ~ index.js:23 ~ getHistoriesCredentials ~ error:', error);
    yield put(setHistories({ isLoading: false, data: [], isError: true }));
  }
}

export function* onOrderStart() {
  yield takeLatest(types.GET_HISTORIES, getHistoriesCredentials);
}

export function* orderSagas() {
  yield all([call(onOrderStart)]);
}
