import React from "react";
import "../dashboard.scss";

const MainDashBoard = () => {
  return (
    <>
      <div>Dashboard</div>
    </>
  );
};
export default MainDashBoard;
