import types from './orderActionTypes';

const initialState = {
  histories: { isLoading: true, data: {}, isError: false },
};

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_HISTORIES:
      return { ...state, histories: action.payload };
    default:
      return state;
  }
};

export default orderReducer;
