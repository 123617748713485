import React from 'react';
import { Button, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { API_CONFIGS, AUTHORIZATION_KEY } from '../../utils/configs';

const UploadFile = (props) => {
  const { isMultiple, acceptFile, maxCount, handleChange, handleRemove, files, listType, name } =
    props;

  let accessToken = localStorage.getItem(AUTHORIZATION_KEY.ACCESS_TOKEN);

  // Config Upload file
  const propsUpload = {
    multiple: isMultiple ?? false,
    accept: acceptFile ?? '',
    maxCount: maxCount ?? 1,
    listType: listType ?? 'text',
    action: `${API_CONFIGS.API_BASE_URL}/api/media/upload`,
    name: name,
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
    onChange(info) {
      return handleChange(info);
    },
    onRemove(event) {
      if (!handleRemove) return true;

      return handleRemove(event);
    },
    fileList: files ?? [],
  };

  return (
    <div>
      <Upload {...propsUpload}>
        <Button icon={<UploadOutlined />}>Upload Image</Button>
      </Upload>
    </div>
  );
};

export default UploadFile;
