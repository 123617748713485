import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//import { registerStart } from "../../store/register/registerActions";
import CloseIcon from '@mui/icons-material/Close';
import { Button, DialogContent, DialogTitle, IconButton, TextField } from '@mui/material';
import LoadingWrapper from '../../../../components/LoadingCustom/LoadingWrapper';
import {
  addMemberStart,
  addMemberSuccess,
} from '../../../../store/user/addMember/addMemberActions';
import { getMember } from '../../../../store/user/member/memberActions';

const AddNewBodyguard = ({ pageSize, setOpenAdd, setLoadingList, handleClose }) => {
  const dispatch = useDispatch();
  const roleID = 5;

  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState(null);

  const [addMemberStatus] = useSelector((state) => [state.addMemberReducer.items]);

  useEffect(() => {
    if (addMemberStatus.status === true) {
      setLoadingList(true);
      setLoading(false);
      setOpenAdd(false);

      dispatch(getMember({ roleID: roleID, page: 1, per_page: pageSize }));
      resetStatusAction();
    } else if (!addMemberStatus.status && addMemberStatus.isError) {
      setLoading(false);
      resetStatusAction();
    }
  }, [addMemberStatus]);

  const resetStatusAction = () => {
    return dispatch(addMemberSuccess({ isLoading: false, status: false, isError: false }));
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
      role: `${roleID}`,
    });
  };

  const handleSubmit = () => {
    if (!values.name) {
      alert('Please enter field FullName');
      return;
    }

    if (!values.password) {
      alert('Please enter field Password');
      return;
    }

    if (!values.phone) {
      alert('Please enter field Phone');
      return;
    }

    if (!values.email) {
      alert('Please enter field Email');
      return;
    }

    setLoading(true);

    // Handle submit success
    dispatch(addMemberStart(values));
  };

  return (
    <>
      <DialogTitle className="fw-bold">
        <span>Add New BodyGuard</span>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <LoadingWrapper loading={loading}>
          <div className="addNewBodyGuard_main fs-15 pt-2 pb-2">
            <form className="" autoComplete="off" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-12 col-lg-6 mb-2">
                  <TextField
                    name="name"
                    fullWidth
                    required
                    id="outlined-fullname"
                    label="Fullname"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-12 col-lg-6 mb-2">
                  <TextField
                    name="password"
                    type={'password'}
                    fullWidth
                    required
                    id="outlined-password"
                    label="Password"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-12 col-lg-6 mb-2">
                  <TextField
                    name="phone"
                    fullWidth
                    required
                    id="phone"
                    label="Phone"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-12 col-lg-6 mb-2">
                  <TextField
                    name="email"
                    required
                    fullWidth
                    id="outlined-email"
                    label="Email"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-12 col-lg-6 mb-2">
                  <TextField
                    name="address"
                    fullWidth
                    id="outlined-address"
                    label="Address"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="mw-930 mx-auto text-end">
                <Button
                  color="primary"
                  className="fw-bold"
                  variant="contained"
                  size="large"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </LoadingWrapper>
      </DialogContent>
    </>
  );
};
export default AddNewBodyguard;
